import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { selectEntity } from '../_redux/authCrud';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { appConfigs } from '../../../../_chamasoft/_helpers';

import { useParams } from 'react-router';

const initialValues = {
    entity: '',
};

function EntityType(props) {
    document.title = 'Select entity — ' + appConfigs.app_name;
    let token = props.history.location.state?.token;
    let { type } = useParams();
    type === 'phone'
        ? (type = 'phone')
        : type === undefined
        ? (type = '')
        : (type = 'email');
    const { intl } = props;
    //const { updateSelectedEntityType } = props;
    const [_entity, setEntity] = useState(initialValues.entity);
    const [loading, setLoading] = useState(false);
    var alert;
    !alert && type !== ''
        ? (alert = {
              msg: intl.formatMessage(
                  { id: 'AUTH.FORGOT.CONFIRMATION.INTRO' },
                  { type: type },
              ),
              type: 'info',
          })
        : (alert = props.history.location.state?.alert);
    const EntityTypeSchema = Yup.object().shape({
        entity: Yup.string().required(
            intl.formatMessage(
                { id: 'AUTH.VALIDATION.REQUIRED' },
                { name: 'Entity type' },
            ),
        ),
    });

    const entityChanged = val => {
        setEntity(val);
        const slug =
            _entities.find(e => {
                return e.value === val;
            })?.slug || '';
        props.history.replace(`/auth/entity-type?for=${slug}`, { token });
        //updateSelectedEntityType(val);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: EntityTypeSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var navigateTo = '';
            var navigateAlert = {};
            var user = {};

            setLoading(true);
            setSubmitting(true);
            selectEntity(values.entity, token)
                .then(resp => {
                    // console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        user = resp.data.user;
                        switch (user.profile) {
                            case 'cs-bank':
                                navigateTo = '/auth/bank-options';
                                break;
                            case 'cs-chama':
                                navigateTo = '/auth/chama-options';
                                break;
                            case 'cs-sacco':
                                navigateTo = '/auth/sacco-options';
                                break;
                            case 'cs-individual':
                                navigateTo = '/auth/individual-options';
                                break;
                            case 'cs-sme':
                                navigateTo = '/auth/sme-options';
                                break;
                            case 'cs-crm':
                                navigateTo = '/auth/crm-options';
                                break;
                            case 'cs-lending':
                                navigateTo = '/auth/lending-options';
                                break;
                            case 'cs-rental':
                                navigateTo = '/auth/rental-options';
                                break;
                            default:
                                navigateTo = '/auth/bank-options';
                                break;
                        }
                    } else {
                        navigateAlert = {
                            msg:
                                'Sorry, your request could not be processes. Please try again.',
                            type: 'danger',
                        };
                        navigateTo = '/auth/login';
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setLoading(false);
                    setStatus(
                        intl.formatMessage({
                            id: 'AUTH.RESET.CONFIRMATION.ERROR',
                        }),
                    );
                })
                .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                            user: user,
                        });
                });
        },
    });

    const _entities = [
        // {
        //     value: 'cs-chama',
        //     name: 'Chama',
        //     description:
        //         'Local investment group, commonly known as Chama in Kenya',
        //     active: true,
        //     slug: 'chamas',
        // },
        // {
        //     value: 'cs-sacco',
        //     name: 'SACCO',
        //     description: 'Savings & Credit Co-operative',
        //     active: true,
        //     slug: 'saccos',
        // },
        // {
        //     value: 'cs-lending',
        //     name: 'Loan Lending',
        //     description:
        //         'Manage your loanees profiles with easy onboarding and payment tracking',
        //     active: true,
        //     slug: 'lending',
        // },
        // {
        //     value: 'cs-bank',
        //     name: 'Bank',
        //     description:
        //         'Bank institution with own investment groups or co-operatives',
        //     active: true,
        //     slug: 'banks',
        // },
        // {
        //     value: 'cs-sme',
        //     name: 'SME',
        //     description:
        //         'Manage your small business financials with easy payments and reconciliation',
        //     active: false,
        //     slug: 'smes',
        // },
        {
            value: 'cs-rental',
            name: 'Rental',
            description:
                'Manage multiple rental properties by automating payment collection and communication',
            active: true,
            slug: 'rentals',
        },
        // {
        //     value: 'cs-individual',
        //     name: 'Individual',
        //     description:
        //         'Manage individual saving goals and keep track of spending',
        //     active: true,
        //     slug: 'personal',
        // },
        // {
        //     value: 'cs-crm',
        //     name: 'CRM',
        //     description: 'Manage your customers and track activities and leads',
        //     active: false,
        //     slug: 'crm',
        // },
    ];

    const renderRadios = () => {
        let _radios = [];
        _entities.forEach((elem, i) => {
            _radios.push(
                <div key={i}>
                    <FormControlLabel
                        value={elem.value}
                        className={elem.value === _entity ? '_active' : ''}
                        control={<Radio />}
                        label={elem.name}
                        disabled={!elem.active}
                        onClick={() => {
                            if (elem.active) entityChanged(elem.value);
                        }}
                    />
                    <div className="rad-descr">
                        <p
                            className={
                                (_entity === elem.value
                                    ? 'text-primary '
                                    : 'text-dark-50 ') +
                                (!elem.active ? 'opacity-60' : '')
                            }
                        >
                            {elem.description}
                        </p>
                    </div>
                </div>,
            );
        });
        return _radios;
    };

    return (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
            {/* begin::Head */}
            <div className="text-left mb-5 mb-lg-20-">
                <h3 className="font-weight-bolder mb-0">
                    <FormattedMessage id="AUTH.RESET.ENTITY_TYPE" />
                </h3>
                <p className="text-dark-50 font-weight-normal">
                    <FormattedMessage id="AUTH.RESET.ENTITY_TYPE.DESCRIPTION" />
                </p>
            </div>
            {/* end::Head */}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
                {formik.status ? (
                    <div
                        className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                    >
                        <div className="alert-text font-weight-bold">
                            {formik.status.msg}
                        </div>
                    </div>
                ) : alert ? (
                    <div
                        className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                    >
                        <div className="alert-text font-weight-bold">
                            {alert.msg}
                        </div>
                    </div>
                ) : null}

                <div className="form-group fv-plugins-icon-container mb-5 entity-types">
                    <RadioGroup
                        name="entity"
                        value={_entity}
                        onChange={setEntity}
                        {...formik.getFieldProps('entity')}
                    >
                        {renderRadios()}
                    </RadioGroup>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                        disabled={formik.isSubmitting || _entity === ''}
                    >
                        <span>
                            <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                        </span>
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>
                    <span></span>
                    {/* <Link 
            to="/auth/login"
            className="btn btn-outline-secondary btn-pill btn-sm font-weight-bold px-15 py-4"
            >
              <FormattedMessage id="AUTH.GENERAL.LOGIN" />
          </Link> */}
                </div>
            </form>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(EntityType));
