import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TermsAndConditions } from './TermsAndConditions';
import { PrivacyPolicy } from './PrivacyPolicy';

export default function TermsConditionsAndPolicy() {
    return (
        <>
            <Switch>
                <Redirect from="/terms" exact={true} to="/terms" />
                <Route path="/terms" component={TermsAndConditions} />
            </Switch>
            <Switch>
                <Redirect from="/policy" exact={true} to="/policy" />
                <Route path="/policy" component={PrivacyPolicy} />
            </Switch>
        </>
    );
}
