import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';
import { useFormik } from 'formik';
import { appConfigs, toAbsoluteUrl } from '../../../../_chamasoft/_helpers';
import SVG from 'react-inlinesvg';
import { verifyJoinRequest, joinRequest } from '../_redux/authCrud';
import { Link } from 'react-router-dom';
import '../../../../checkin.scss';
import { UserProfileDropdown } from '../../../../_chamasoft/layout/components/extras/dropdowns/UserProfileDropdown';
import { useParams } from 'react-router';
// Reference: https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Join(props) {
    document.title = 'Join — ' + appConfigs.app_name;
    const { intl } = props;
    let { hex } = useParams();
    var [alert, setAlert] = useState(null);
    if (!hex) {
        props.history.replace('/auth/login', {
            alert: {
                msg:
                    'Sorry, the requested page is not available. Log in to continue.',
                type: 'danger',
            },
        });
    }
    // console.log(user);
    const [fetchingDataStatus, setFetchingDataStatus] = useState(true);
    const [profile, setProfile] = useState(null);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [status, setStatus] = useState('Processing...');
    const [name, setName] = useState('');
    const [accepting, setAccepting] = useState(false);
    const [declining, setDeclining] = useState(false);
    const [signup, setSignup] = useState(false);

    const [showPassword, setshowPasswordValue] = useState(false);
    const recaptchaRef = React.useRef();
    const [initialValues, setInitialValues] = useState({
        fullname: '',
        email: '',
        phone: '',
        password: '',
        acceptTerms: false,
    });
    const RegistrationSchema = Yup.object().shape({
        fullname: Yup.string()
            .min(
                3,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Full name', min: '3' },
                ),
            )
            .max(
                100,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Full name', max: '100' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Full name' },
                ),
            ),
        // email: Yup.string()
        //   .email("Wrong email format")
        //   .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Email' }))
        //   .min(5, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH", }, { name: 'Email', min: '5' }))
        //   .max(250, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH", }, { name: 'Email', max: '250' })),
        // phone: Yup.string()
        //   .matches(phoneRegExp, 'Phone number is not valid')
        //   .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Phone' })),
        password: Yup.string()
            .min(
                8,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Password', min: '8' },
                ),
            )
            .max(
                50,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Password', max: '50' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Password' },
                ),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            intl.formatMessage(
                { id: 'AUTH.VALIDATION.PASSWORD_MISMATCH' },
                // { id: 'The password and its confirm are not the same' },
                { name: 'Confirm Password' },
            ),
        ),
        acceptTerms: Yup.bool().required(
            intl.formatMessage({ id: 'AUTH.VALIDATION.AGREEMENT_REQUIRED' }),
        ),
    });

    const handleClickShowPassword = () => {
        setshowPasswordValue(!showPassword);
    };

    useEffect(() => {
        var navigateAlert = {};
        var navigateUrl = '';
        verifyJoinRequest(hex)
            .then(res => {
                // console.log(res)
                if (res.data.result_code === 1) {
                    if (res.data.action === 'no-invite') {
                        setStatus('Oops! Something weird just happened.');
                        navigateAlert = {
                            msg:
                                'An error occurred. We could not locate your invite. Check the invite link shared and try again.',
                            type: 'danger',
                        };
                        navigateUrl = '/auth/login';
                    } else if (res.data.action === 'no-user') {
                        setStatus('Continue to join');
                        setProfile(res.data.profile);
                        setName(res.data.invitee?.first_name || '');
                        setUserData(res.data.invitee);
                        navigateAlert = {
                            msg:
                                'Hello ' +
                                res.data.invitee?.first_name +
                                ', you have been invited to join ' +
                                res.data.profile.name +
                                ' (' +
                                (res.data.profile.profile === 'cs-rental'
                                    ? 'Rental'
                                    : 'NGO') +
                                '), as ' +
                                (res.data.profile.type === 'agent'
                                    ? 'an'
                                    : 'a') +
                                ' ' +
                                res.data.invitee.role +
                                '. Create your account to accept the invitation.',
                            type: 'info',
                        };
                        setInitialValues({
                            fullname:
                                res.data.invitee?.first_name +
                                ' ' +
                                res.data.invitee?.last_name,
                            email: res.data.invitee?.email,
                            phone: res.data.invitee?.phone,
                            password: '',
                            acceptTerms: false,
                        });
                        setSignup(true);
                    } else if (res.data.action === 'user-exists') {
                        // navigateAlert = { msg: "", type: "" };
                        setStatus('Continue to join');
                        setUser(res.data.user);
                        setName(res.data.user?.first_name || '');
                        setProfile(res.data.profile);
                    } else {
                        // default conditions here
                    }
                } else {
                    navigateAlert = {
                        msg:
                            'An error occurred. We could not locate your invite. Check the invite link shared and try again.',
                        type: 'danger',
                    };
                    setStatus('Oops! Something weird just happened.');
                }
            })
            .catch(err => {
                navigateAlert = {
                    msg:
                        'An error occurred. We could not locate your invite. Check the invite link shared and try again.',
                    type: 'danger',
                };
                setStatus('Oops! Something weird just happened.');
            })
            .finally(() => {
                setFetchingDataStatus(false);
                if (navigateUrl !== '')
                    props.history.replace(navigateUrl, {
                        alert: navigateAlert,
                    });
                else setAlert(navigateAlert?.type ? navigateAlert : null);
            });
    }, [props.history, hex]);

    const respond = type => {
        var respo = '';
        var navigateTo = '';
        var navigateAlert = {};
        if (type === 'accepting') {
            setAccepting(true);
            respo = 'accept';
        } else {
            setDeclining(true);
            respo = 'decline';
        }
        var _user = {
            first_name: '',
            last_name: '',
            phone: '',
            password: '',
        };
        joinRequest(hex, respo, _user, 0)
            .then(res => {
                if (res.data.result_code === 1) {
                    navigateTo = '/auth/login';
                    navigateAlert = {
                        type: 'success',
                        msg:
                            'Your response to the invitation was successfully processed. Log in to continue.',
                    };
                } else {
                    navigateAlert = {
                        msg:
                            'Sorry, we could not load the requested option, please try again.',
                        type: 'danger',
                    };
                }
            })
            .catch(err => {
                navigateAlert = {
                    msg:
                        'Sorry, we could not load the requested option, please try again.',
                    type: 'danger',
                };
            })
            .finally(() => {
                setAccepting(false);
                setDeclining(false);
                if (navigateTo !== '')
                    props.history.replace(navigateTo, { alert: navigateAlert });
                else setAlert(navigateAlert);
            });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            var navigateTo = '';
            var navigateAlert = {};
            var names = values.fullname.split(' ');

            if (names.length < 2) {
                setStatus({
                    msg: intl.formatMessage(
                        { id: 'AUTH.VALIDATION.REQUIRED' },
                        { name: 'Full name' },
                    ),
                    type: 'danger',
                });
                setAccepting(false);
                setDeclining(false);
                return;
            }

            var firstname = names[0];
            var middlename = names[1];
            var lastname =
                names.length > 2 ? names[1] + ' ' + names[2] : names[1];
            var _user = {
                first_name: firstname,
                middle_name: middlename,
                last_name: lastname,
                email: values.email,
                password: values.password,
                phone: userData.phone,
                role: userData.role,
            };

            const onSubmitWithReCAPTCHA = async () => {
                const token = await recaptchaRef.current.executeAsync();
                // console.log("Captcha value:", token);
                if (!token) {
                    return false;
                }
                return true;
            };

            if (!onSubmitWithReCAPTCHA()) {
                setAccepting(false);
                setDeclining(false);
                setStatus({
                    msg:
                        'Sorry, an error occurred. Captcha validation failed, terribly! Please try again.',
                    type: 'danger',
                });
                return;
            }

            setAccepting(true);
            joinRequest(hex, 'accept', _user, 1)
                .then(res => {
                    if (res.data.result_code === 1) {
                        navigateTo = '/auth/login';
                        navigateAlert = {
                            type: 'success',
                            msg:
                                'Your response to the invitation was successfully processed. Log in to continue.',
                        };
                    } else {
                        navigateAlert = {
                            msg:
                                'Sorry, we could not load the requested option, please try again.',
                            type: 'danger',
                        };
                    }
                })
                .catch(err => {
                    navigateAlert = {
                        msg:
                            'Sorry, we could not load the requested option, please try again later.',
                        type: 'danger',
                    };
                })
                .finally(() => {
                    setAccepting(false);
                    if (navigateTo !== '')
                        props.history.replace(navigateTo, {
                            alert: navigateAlert,
                        });
                    else setAlert(navigateAlert);
                });
        },
    });

    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) return true;
        if (formik.touched[fieldname] && !formik.errors[fieldname])
            return false;
        return false;
    };

    return (
        <>
            <div className="login-form">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-end pb-10">
                        {user && (
                            <UserProfileDropdown
                                user={user}
                                showLogout={false}
                            />
                        )}
                    </div>
                    <div className="col-md-12">
                        <div className=" text-center">
                            <Link to="/">
                                <img
                                    className="d-block mx-auto mb-4"
                                    src={`${toAbsoluteUrl(
                                        '/media/logos/chamasoft_new_logo.png',
                                    )}`}
                                    alt="chamasoft"
                                    style={{
                                        height: '36px',
                                        marginLeft: '-6px',
                                    }}
                                />
                            </Link>
                            <h4 className="_title m-0">
                                <span>Join</span> realtors
                            </h4>
                            <p className="_title-descr m-0">{status}</p>
                        </div>

                        {alert ? (
                            <div
                                className={`my-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <button
                                    type="button"
                                    className="close d-none"
                                    onClick={() => setAlert(null)}
                                >
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close alert</span>
                                </button>
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}

                        {/* <div className="row py-5 d-none">
                            <div className="col-md-12">
                                <Link
                                    to={{
                                        pathname: '/auth/entity-type',
                                        state: { token: user?.access_token },
                                    }}
                                    className={
                                        'btn btn-pill- btn-primary btn-block ' +
                                        (fetchingDataStatus ? 'disabled' : '')
                                    }
                                >
                                    <strong>Register</strong> Bank, NGO, SACCO,
                                    Chama &amp; More
                                    <span className="_indico svg-icon svg-icon-md ml-2">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                '/media/svg/icons/Navigation/Angle-double-right.svg',
                                            )}
                                        />
                                    </span>
                                </Link>
                            </div>
                        </div> */}

                        {fetchingDataStatus && (
                            <div className="d-flex flex-center">
                                <svg
                                    className="splash-spinner"
                                    viewBox="0 0 50 50"
                                    style={{ marginLeft: '0' }}
                                >
                                    <circle
                                        className="path"
                                        cx="25"
                                        cy="25"
                                        r="10"
                                        fill="none"
                                        strokeWidth="2"
                                    ></circle>
                                </svg>
                            </div>
                        )}

                        {!fetchingDataStatus && profile && !signup && (
                            <div className="text-center my-5">
                                <span className="_indico svg-icon svg-icon-xl svg-icon-dark-50">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            '/media/svg/icons/Communication/Group.svg',
                                        )}
                                    />
                                </span>
                                <h6 className="d-block text-dark-50 font-weight-bolder">
                                    {profile.name}
                                </h6>
                                <small className="d-block text-dark-50">
                                    Hello {name}, you have been invited to join
                                    this{' '}
                                    {profile.profile === 'cs-bank'
                                        ? 'Bank'
                                        : profile.profile === 'cs-rental'
                                        ? 'Rental'
                                        : 'NGO'}{' '}
                                    as {profile.type === 'agent' ? 'an' : 'a'}{' '}
                                    <strong>{profile.type}</strong>.
                                </small>
                                <p className="d-block text-dark-50 mt-5">
                                    Do you accept this invitation?
                                </p>
                                <button
                                    className="btn btn-primary btn-md px-5 py-2 btn-pill mb-2 mr-5"
                                    disabled={accepting || declining}
                                    onClick={() => respond('accepting')}
                                >
                                    Yes, I accept
                                    {accepting && (
                                        <span className="ml-3 mr-4 spinner spinner-white"></span>
                                    )}
                                </button>
                                <button
                                    className="btn btn-danger btn-md px-5 py-2 btn-pill mb-2"
                                    disabled={accepting || declining}
                                    onClick={() => respond('declining')}
                                >
                                    No, I decline
                                    {declining && (
                                        <span className="ml-3 mr-4 spinner spinner-white"></span>
                                    )}
                                </button>
                            </div>
                        )}

                        {signup && (
                            <div className="my-5">
                                <form
                                    id="kt_login_signin_form"
                                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <ReCAPTCHA
                                        sitekey={appConfigs.recaptcha_site_key}
                                        size="invisible"
                                        ref={recaptchaRef}
                                    />
                                    {/* begin: Fullname */}
                                    <div className="form-group fv-plugins-icon-container mb-1">
                                        <TextField
                                            error={inputHasError('fullname')}
                                            label="Full name"
                                            name="fullname"
                                            type="text"
                                            autoComplete="name"
                                            {...formik.getFieldProps(
                                                'fullname',
                                            )}
                                            margin="normal"
                                        />
                                        {formik.touched.fullname &&
                                        formik.errors.fullname ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.fullname}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Fullname */}

                                    {/* begin: Email */}
                                    <div className="form-group fv-plugins-icon-container mb-1">
                                        <TextField
                                            error={inputHasError('email')}
                                            label="Email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            {...formik.getFieldProps('email')}
                                            margin="normal"
                                        />
                                        {formik.touched.email &&
                                        formik.errors.email ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.email}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Email */}

                                    {/* begin: Password */}
                                    <div className="form-group fv-plugins-icon-container mb-0">
                                        <TextField
                                            error={inputHasError('password')}
                                            label="New password"
                                            name="password"
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            autoComplete="new-password"
                                            {...formik.getFieldProps(
                                                'password',
                                            )}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="Toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {formik.touched.password &&
                                        formik.errors.password ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.password}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Password */}

                                    {/* begin: Confirm Password */}
                                    <div className="form-group fv-plugins-icon-container mb-2-">
                                        <TextField
                                            error={inputHasError(
                                                'confirmPassword',
                                            )}
                                            label="Confirm password"
                                            name="confirmPassword"
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            autoComplete="confirmPassword"
                                            {...formik.getFieldProps(
                                                'confirmPassword',
                                            )}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="Toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {formik.touched.confirmPassword &&
                                        formik.errors.confirmPassword ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block w-100">
                                                    {
                                                        formik.errors
                                                            .confirmPassword
                                                    }
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Confirm Password */}

                                    {/* begin: Terms and Conditions */}
                                    <div className="form-group mt-2">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                name="acceptTerms"
                                                className="m-1"
                                                {...formik.getFieldProps(
                                                    'acceptTerms',
                                                )}
                                            />
                                            <span></span> &nbsp;&nbsp; I agree
                                            to the &nbsp;
                                            <Link
                                                to="/terms"
                                                target="_blank"
                                                className="mr-1"
                                                rel="noopener noreferrer"
                                            >
                                                Terms &amp; Conditions
                                            </Link>
                                        </label>
                                        {formik.touched.acceptTerms &&
                                        formik.errors.acceptTerms ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.acceptTerms}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Terms and Conditions */}

                                    <div className="form-group d-flex justify-content-between align-items-center">
                                        <button
                                            className="btn btn-primary btn-md px-5 py-2 btn-pill mb-2 mr-5"
                                            type="submit"
                                            disabled={
                                                accepting ||
                                                declining ||
                                                !formik.values.acceptTerms
                                            }
                                        >
                                            Accept &amp; create account
                                            {accepting && (
                                                <span className="ml-3 mr-4 spinner spinner-white"></span>
                                            )}
                                        </button>
                                        <button
                                            className="btn btn-danger btn-md px-5 py-2 btn-pill mb-2"
                                            disabled={accepting || declining}
                                            onClick={() => respond('declining')}
                                        >
                                            Decline invitation
                                            {declining && (
                                                <span className="ml-3 mr-4 spinner spinner-white"></span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}

                        {!fetchingDataStatus && !profile && (
                            <div className="text-center my-5">
                                <h6 className="d-block text-dark-50 font-weight-bolder">
                                    No options
                                </h6>
                                <small className="d-block text-dark-50">
                                    Your Realtors will
                                    <br />
                                    appear here when available.
                                </small>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(Join));
