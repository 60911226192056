import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { bankSignup, getBanks, getCountries } from '../_redux/authCrud';
import { Redirect } from 'react-router';
import { appConfigs } from '../../../../_chamasoft/_helpers';
// import { countries } from "../../../../_chamasoft/_assets/countries";
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiPhoneInput from 'material-ui-phone-number';

const initialValues = {
    bank: '',
    phone: '',
    email: '',
    country: '',
    name: '',
    // address: "",
    // website: "",
};

function CsBank(props) {
    document.title = 'Bank options — ' + appConfigs.app_name;
    const { intl } = props;
    // const dispatch = useDispatch();
    var [alert, setAlert] = useState(null);
    let user = props.history.location.state?.user;
    const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    // const websiteRegExp = /^(ftp|http|https):\/\/[^ "]+$/im
    const [fetchingDataStatus, setFetching] = useState(true);
    const [fetchingBanksStatus, setFetchingBanks] = useState(false);

    const [countries, setCountries] = useState([]);
    const [countryIdObj, setCountryIdObj] = useState(null);

    const [banks, setBanks] = useState([]);
    const [bankIdObj, setBankIdObj] = useState(null);

    useEffect(() => {
        if (fetchingDataStatus) {
            getCountries()
                .then(res => {
                    let newArr = res.data.map(el => {
                        return { _id: el._id, code: el.code, label: el.label };
                    });
                    setCountries(newArr);
                })
                .catch(err => {
                    setCountries([]);
                })
                .finally(() => {
                    setFetching(false);
                });
        }
    }, [fetchingDataStatus]);

    const isValid = () => {
        // return true;
        if (!user || user === undefined || user === null || user === {})
            return false;
        else return true;
    };

    const CsBankSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .min(
                5,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Email', min: '5' },
                ),
            )
            .max(
                250,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Email', max: '250' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Email' },
                ),
            ),
        country: Yup.string().required(
            intl.formatMessage(
                { id: 'AUTH.VALIDATION.REQUIRED' },
                { name: 'Country' },
            ),
        ),
        bank: Yup.string().required(
            intl.formatMessage(
                { id: 'AUTH.VALIDATION.REQUIRED' },
                { name: 'Bank' },
            ),
        ),
        // website: Yup.string()
        //   .matches(websiteRegExp, 'Website is not valid')
        //   .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Website' })),
        // address: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED", }, { name: 'Address' })),
        phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Phone' },
                ),
            ),
    });

    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) return true;
        if (formik.touched[fieldname] && !formik.errors[fieldname])
            return false;
        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: CsBankSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var token = '';
            var navigateTo = '';
            var navigateAlert = {};

            setSubmitting(true);

            bankSignup(
                user?.access_token || '',
                values.bank,
                bankIdObj.name,
                values.email,
                countryIdObj,
                values.phone,
            )
                .then(resp => {
                    // console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        token = resp.data.redirect_string || '';
                        // let _host = localStorage.getItem('_back') ? localStorage.getItem('_back') : 'https://banks.chamasoft.com';
                        if (resp.data.bank.is_validated === 0) {
                            navigateTo = '/auth/pending';
                        } else if (resp.data.bank.is_validated === 1) {
                            // window.location.href = _host + '/auth/verify-user/' + token;
                            navigateTo = '/auth/options';
                        } else {
                            navigateTo = '/auth/login';
                            navigateAlert = {
                                msg:
                                    "Sorry, we can't allow you to login at this point. Kindly reach out to us on +254733366240 for assistance.",
                                type: 'danger',
                            };
                        }
                        // localStorage.removeItem('_back');
                    } else if (
                        resp.data.result_code === 2 ||
                        resp.data.result_code === 7
                    ) {
                        navigateAlert = {
                            msg:
                                'Your session has expired, log in again to continue.',
                            type: 'danger',
                        };
                        navigateTo = '/auth/login';
                    } else {
                        // navigateAlert = { msg: intl.formatMessage({ id: "AUTH.PAIR.ACCOUNT.ERROR", }), type: 'danger' };
                        setAlert({ msg: resp.data.message, type: 'danger' });
                        navigateTo = '';
                        // navigateTo = "/auth/login";
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({ id: 'AUTH.PAIR.ACCOUNT.ERROR' }),
                    );
                })
                .finally(() => {
                    setSubmitting(false);
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                            token,
                            user,
                        });
                });
        },
    });

    const renderFetchData = () => {
        return fetchingDataStatus ? (
            <div className="">
                <svg
                    className="splash-spinner"
                    viewBox="0 0 50 50"
                    style={{ marginLeft: '0px' }}
                >
                    <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="10"
                        fill="none"
                        strokeWidth="2"
                    ></circle>
                </svg>
            </div>
        ) : (
            <></>
        );
    };

    const countryChanged = value => {
        formik.setFieldValue('country', value?._id);
        setCountryIdObj(value);
        setBankIdObj(null);
        setFetchingBanks(true);
        getBanks(value._id)
            .then(res => {
                let newArr = res.data.map(el => {
                    return {
                        _id: el._id,
                        bankCode: el.bankCode,
                        name: el.name,
                        slug: el.slug,
                    };
                });
                setBanks(newArr);
            })
            .catch(err => {
                setBanks([]);
            })
            .finally(() => {
                setFetchingBanks(false);
            });
    };

    return (
        <>
            {!isValid() && <Redirect to="/auth" />}
            {isValid() && (
                <div
                    className="login-form bank-options"
                    style={{ display: 'block' }}
                >
                    {/* begin::Head */}
                    <div className="text-left mb-5 mb-lg-20- text-primary">
                        <h1
                            className="font-weight-normal mb-0"
                            style={{ fontSize: '36px' }}
                        >
                            Hello,{' '}
                            <strong className="font-weight-bolder">
                                {user?.first_name}
                            </strong>
                        </h1>
                        <h5 className="font-weight-normal mb-0">
                            We're excited to have you and your financial
                            institution join us, welcome aboard.
                        </h5>
                    </div>
                    <div className="text-left mb-0 mt-5">
                        <p className="text-dark-50 font-weight-normal">
                            Before we continue, fill in the details below.
                        </p>
                    </div>
                    {/* end::Head */}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                            >
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlert(null)}
                                >
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close alert</span>
                                </button>
                                <div className="alert-text font-weight-bold">
                                    {formik.status.msg}
                                </div>
                            </div>
                        ) : alert ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setAlert(null)}
                                >
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close alert</span>
                                </button>
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}
                        {renderFetchData()}
                        <div
                            className={
                                'row ' + (fetchingDataStatus ? 'd-none' : '')
                            }
                        >
                            <div className="col-md-12">
                                <div className="form-group fv-plugins-icon-container mb-2 mt-5">
                                    <Autocomplete
                                        value={countryIdObj}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                countryChanged(newValue);
                                            }
                                        }}
                                        id="country"
                                        options={countries}
                                        style={{ width: '100%' }}
                                        getOptionLabel={(option, value) =>
                                            option && option.label
                                                ? option.label
                                                : ''
                                        }
                                        getOptionSelected={(option, value) => {
                                            if (value === '') {
                                                return true;
                                            } else if (
                                                value._id === option._id
                                            ) {
                                                return true;
                                            }
                                        }}
                                        blurOnSelect
                                        clearOnEscape
                                        renderOption={option => (
                                            <React.Fragment>
                                                {option.label} ({option.code})
                                            </React.Fragment>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                autoComplete="off"
                                                name={'country'}
                                                value={countryIdObj}
                                                {...params}
                                                error={inputHasError('country')}
                                                // {...formik.getFieldProps("country")}
                                                label="Country"
                                                helperText="Select country"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    'col-md-12 ' +
                                    (countryIdObj ? '' : 'd-none')
                                }
                            >
                                <div className="form-group fv-plugins-icon-container mb-2 mt-0">
                                    <Autocomplete
                                        disabled={fetchingBanksStatus}
                                        value={bankIdObj}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setBankIdObj(newValue);
                                                formik.setFieldValue(
                                                    'bank',
                                                    newValue?._id,
                                                );
                                            }
                                        }}
                                        id="bank"
                                        options={banks}
                                        style={{ width: '100%' }}
                                        getOptionLabel={(option, value) =>
                                            option && option.name
                                                ? option.name
                                                : ''
                                        }
                                        getOptionSelected={(option, value) => {
                                            if (value === '') {
                                                return true;
                                            } else if (
                                                value._id === option._id
                                            ) {
                                                return true;
                                            }
                                        }}
                                        blurOnSelect
                                        clearOnEscape
                                        renderOption={option => (
                                            <React.Fragment>
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                autoComplete="off"
                                                name={'bank'}
                                                value={bankIdObj}
                                                {...params}
                                                error={inputHasError('bank')}
                                                // {...formik.getFieldProps("bank")}
                                                label="Bank name"
                                                helperText="Select your bank"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    'col-md-12 ' +
                                    (countryIdObj && bankIdObj ? '' : 'd-none')
                                }
                            >
                                <div
                                    className={
                                        'form-group fv-plugins-icon-container mb-2 mt-0'
                                    }
                                >
                                    <input
                                        type="hidden"
                                        name={'phone'}
                                        value={formik.values.phone}
                                        {...formik.getFieldProps('phone')}
                                    />
                                    <MuiPhoneInput
                                        label="Bank phone"
                                        name={'_phone'}
                                        value={formik.values.phone}
                                        countryCodeEditable={false}
                                        inputClass={'cs-phone-inp'}
                                        defaultCountry={
                                            (countryIdObj?.code
                                                ? (countryIdObj?.code).toLowerCase()
                                                : 'ke') || 'ke'
                                        }
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onKeyUp={e =>
                                            formik.setFieldValue(
                                                'phone',
                                                e.target.value,
                                            )
                                        }
                                        error={inputHasError('phone')}
                                        helperText="Bank phone"
                                        // disabled={savingData}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    'col-md-12 ' +
                                    (countryIdObj && bankIdObj ? '' : 'd-none')
                                }
                            >
                                <div
                                    className={
                                        'form-group fv-plugins-icon-container mb-2 mt-0'
                                    }
                                >
                                    <TextField
                                        error={inputHasError('email')}
                                        label="Bank email"
                                        name="email"
                                        type="email"
                                        {...formik.getFieldProps('email')}
                                        margin="normal"
                                        helperText="Email address for the bank"
                                    />
                                </div>
                            </div>
                            {/* <div className={"col-md-12 " + ((countryIdObj && bankIdObj) ? "" : "d-none")}>
                <div className={"form-group fv-plugins-icon-container mb-2 mt-0"}>
                  <TextField
                    error={inputHasError("website")}
                    label="Bank website"
                    name="website"
                    type="website"
                    {...formik.getFieldProps("website")}
                    margin="normal"
                    helperText="Website for the bank"
                  />
                </div>
              </div>
              <div className={"col-md-12 " + ((countryIdObj && bankIdObj) ? "" : "d-none")}>
                <div className={"form-group fv-plugins-icon-container mb-0 mt-0"}>
                  <TextField
                    error={inputHasError("address")}
                    label="Bank address"
                    name="address"
                    type="text"
                    {...formik.getFieldProps("address")}
                    margin="normal"
                    helperText="Postal address, in full"
                  />
                </div>
              </div>
               */}
                            <div className={'col-md-12'}>
                                <div className="form-group d-flex flex-wrap justify-content-between align-items-center pt-10">
                                    <button
                                        id="kt_login_forgot_submit"
                                        type="submit"
                                        className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                                        disabled={
                                            formik.isSubmitting ||
                                            fetchingBanksStatus ||
                                            !countryIdObj ||
                                            !bankIdObj
                                        }
                                    >
                                        <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                                        {formik.isSubmitting && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(CsBank));
