import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { appConfigs } from '../../../../_chamasoft/_helpers';

function ConfirmRentalCreation(props) {
    document.title = 'Confirm Rental Creation — ' + appConfigs.app_name;
    // const { intl } = props;

    return (
        <>
            <div
                className="login-form bank-options"
                style={{ display: 'block' }}
            >
                <div className="text-left mb-5 mb-lg-20- text-primary">
                    <h1
                        className="font-weight-normal mb-2"
                        style={{ fontSize: '36px' }}
                    >
                        Hello,
                    </h1>
                    <h5 className="font-weight-normal mb-0">
                        Your rental profile has been created successfully.
                        <br />
                        You will be logged out in a few. Please, Log back in to
                        continue the journey.
                    </h5>
                </div>
                <div className="text-left mb-0 mt-5">
                    <p className="text-dark-50 font-weight-normal">
                        If you have any questions or concerns,
                        <br />
                        give us a call <strong>+254 733 366 240</strong>
                    </p>
                </div>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ConfirmRentalCreation));
