export const appConfigs = {
    app_name: process.env.REACT_APP_NAME,
    org_name: process.env.REACT_APP_ORG_NAME,
    org_url: process.env.REACT_APP_ORG_URL,
    api_url: process.env.REACT_APP_API_URL,
    bank_home_api_url: process.env.REACT_APP_BANK_HOME_API_URL,
    ngo_home_api_url: process.env.REACT_APP_NGO_HOME_API_URL,
    rental_home_api_url: process.env.REACT_APP_RENTAL_HOME_API_URL,
    bank_home_url: process.env.REACT_APP_BANK_HOME_URL,
    chama_home_url: process.env.REACT_APP_CHAMA_HOME_URL,
    sacco_home_url: process.env.REACT_APP_SACCO_HOME_URL,
    individual_home_url: process.env.REACT_APP_INDIVIDUAL_HOME_URL,
    lending_home_url: process.env.REACT_APP_LENDING_HOME_URL,
    rental_home_url: process.env.REACT_APP_RENTAL_HOME_URL,
    crm_home_url: process.env.REACT_APP_CRM_HOME_URL,
    sme_home_url: process.env.REACT_APP_SME_HOME_URL,
    ngo_home_url: process.env.REACT_APP_NGO_HOME_URL,
    auth_key: process.env.REACT_APP_AUTH_KEY,
    uploads_url: process.env.REACT_APP_UPLOADS_URL,
    recaptcha_site_key: '6LebyNMUAAAAAPHkKfW_EkbjNbwtvFmd-KDRcEKD',
};
