import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { verifyCode } from '../_redux/authCrud';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
// import { useParams } from 'react-router';
import { appConfigs } from '../../../../_chamasoft/_helpers';
import queryString from 'query-string';
import { resetPassword } from '../_redux/authCrud';

const initialValues = {
    code: '',
};

function ConfirmCode(props) {
    document.title = 'Confirmation code — ' + appConfigs.app_name;
    const getParams = queryString.parse(window.location.search);
    const fiLayout = getParams?.for === 'fi' || false;
    const identity = getParams?.id ? getParams?.id : '';
    let type = getParams?.type ? getParams?.type : '';
    const [isRequested, setIsRequested] = useState(false);
    type === 'phone'
        ? (type = 'phone')
        : type === undefined
        ? (type = '')
        : (type = 'email');
    const { intl } = props;
    var alert;
    !alert && type !== ''
        ? (alert = {
              msg: intl.formatMessage(
                  { id: 'AUTH.FORGOT.CONFIRMATION.INTRO' },
                  { type: type },
              ),
              type: 'info',
          })
        : (alert = props.history.location.state?.alert);
    const ConfirmCodeSchema = Yup.object().shape({
        code: Yup.string()
            .min(
                4,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Code', min: '4' },
                ),
            )
            .max(
                10,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Code', max: '10' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Code' },
                ),
            ),
    });
    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return true;
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return false;
        }

        return false;
    };

    var navigateTo = '';
    var navigateAlert = {};
    const handleResendCode = () => {
        var formatedIdentity;
        if (type === 'email') {
            formatedIdentity = identity.toLowerCase();
        } else if (type === 'phone') {
            // remove spaces in phone number
            formatedIdentity = '+' + identity.replace(/\s/g, '');
        }
        // const [resendStatus, setResendStatus] = useState(false);
        resetPassword(type, formatedIdentity)
            .then(resp => {
                if (resp.data.result_code === 1) {
                    navigateAlert = {
                        msg: intl.formatMessage(
                            {
                                id:
                                    'AUTH.RESET.PASSWORD.PASSWORD_RESET_CODE_SENT',
                            },
                            { identity: formatedIdentity },
                        ),
                        type: 'success',
                    };
                } else {
                    navigateAlert = {
                        msg: intl.formatMessage({
                            id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                        }),
                        type: 'danger',
                    };
                }
            })
            .catch(() => {
                setIsRequested(false);
                navigateAlert = {
                    msg: intl.formatMessage({
                        id: 'Could not resend code',
                    }),
                    type: 'danger',
                };
                // setResendStatus(
                //     intl.formatMessage({
                //         id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                //     }),
                // );
            })
            .finally(() => {
                if (navigateTo !== '')
                    props.history.push(navigateTo, {
                        alert: navigateAlert,
                    });
            });
    };
    const formik = useFormik({
        initialValues,
        validationSchema: ConfirmCodeSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var navigateTo = '';
            var navigateAlert = {};
            verifyCode(values.code)
                .then(resp => {
                    console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.FORGOT.CONFIRMATION.SUCCESS',
                            }),
                            type: 'success',
                        };
                        navigateTo = fiLayout
                            ? '/auth/reset-password/' +
                              resp.data.reset_token +
                              '?for=fi'
                            : '/auth/reset-password/' + resp.data.reset_token;
                    } else {
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.RESET.CONFIRMATION.ERROR',
                            }),
                            type: 'danger',
                        };
                        // navigateTo = "/auth/login";
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({
                            id: 'AUTH.RESET.CONFIRMATION.ERROR',
                        }),
                    );
                })
                .finally(() => {
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                        });
                });
        },
    });
    const renderDescr = () => {
        return type === '' ? (
            <FormattedMessage id="AUTH.FORGOT.CONFIRMATION.DESCRIPTION_ALT" />
        ) : (
            <FormattedMessage
                id="AUTH.FORGOT.CONFIRMATION.DESCRIPTION"
                values={{ type: type }}
            />
        );
    };

    return (
        <>
            {!isRequested && (
                <div
                    className="login-form login-forgot"
                    style={{ display: 'block' }}
                >
                    {/* begin::Head */}
                    <div className="text-left mb-5 mb-lg-20-">
                        <h3 className="font-weight-bolder mb-0">
                            <FormattedMessage id="AUTH.FORGOT.CONFIRMATION" />
                        </h3>
                        <p className="text-dark-50 font-weight-normal">
                            {renderDescr()}
                        </p>
                    </div>
                    {/* end::Head */}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {formik.status.msg}
                                </div>
                            </div>
                        ) : alert ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}

                        <div className="form-group fv-plugins-icon-container mb-5">
                            <TextField
                                error={inputHasError('code')}
                                label="Confirmation code"
                                name="code"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps('code')}
                                margin="normal"
                            />
                            {formik.touched.code && formik.errors.code ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.code}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group text-left- mt-2">
                            <Link
                                onClick={handleResendCode}
                                to={
                                    '/auth/confirm-code/' +
                                    (type === 'phone' ? 'phone' : 'email') +
                                    (fiLayout
                                        ? '?for=fi&id=' + identity
                                        : '?id=' + identity)
                                }
                                className="text-dark-50 font-weight-normal text-hover-primary"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage id="AUTH.RESET.CONFIRMATION.RESEND" />
                            </Link>
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                                disabled={formik.isSubmitting}
                            >
                                <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                            </button>
                            <Link
                                to={'/auth/login' + (fiLayout ? '?for=fi' : '')}
                                className="btn btn-outline-secondary btn-pill btn-sm font-weight-bold px-15 py-4"
                            >
                                <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                            </Link>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}
export default injectIntl(connect(null, auth.actions)(ConfirmCode));
