import { appConfigs } from './AppConfigs';

export function removeCSSClass(ele, cls) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const getProfileURL = profile => {
    switch (profile) {
        case 'cs-bank':
            return appConfigs.bank_home_url;
        case 'cs-chama':
            return appConfigs.chama_home_url;
        case 'cs-sacco':
            return appConfigs.sacco_home_url;
        case 'cs-individual':
            return appConfigs.individual_home_url;
        case 'cs-lending':
            return appConfigs.lending_home_url;
        case 'cs-rental':
            return appConfigs.rental_home_url;
        case 'cs-crm':
            return appConfigs.crm_home_url;
        case 'cs-sme':
            return appConfigs.sme_home_url;
        default:
            return '';
    }
};

export const getProfile = profile => {
    switch (profile) {
        case 'cs-bank':
            return 'Bank';
        case 'cs-chama':
            return 'Chama';
        case 'cs-sacco':
            return 'Sacco';
        case 'cs-individual':
            return 'Individual';
        case 'cs-lending':
            return 'Lending';
        case 'cs-rental':
            return 'Rental';
        case 'cs-crm':
            return 'CRM';
        case 'cs-sme':
            return 'SME';
        default:
            return '';
    }
};
