/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl, appConfigs } from '../../../../_chamasoft/_helpers';
import { ContentRoute } from '../../../../_chamasoft/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ConfirmCode from './ConfirmCode';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import VerifyAccount from './VerifyAccount';
import PendingVerification from './PendingVerification';
import ConfirmRentalCreation from './ConfirmRentalCreation';
import Checkin from './Checkin';
import CsBank from './CsBank';
import CsChama from './CsChama';
import CsIndividual from './CsIndividual';
import CsSacco from './CsSacco';
import CsRental from './CsRental';
import CsLending from './CsLending';
import '../../../../_chamasoft/_assets/sass/pages/login/chamasoft.scss';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import queryString from 'query-string';
import VerifyInstitution from './VerifyInstitution';
import Join from './Join';
import ProductsCarousel from './ProductsCarousel/ProductsCarousel';
import EntityType from './EntityType';

export function AuthPage() {
    const getParams = queryString.parse(window.location.search);

    const current_year = new Date().getFullYear();
    const curr_path = useLocation().pathname;
    let extracted_path = curr_path.split('/');
    extracted_path = extracted_path.slice(0, extracted_path.length - 1);
    extracted_path = extracted_path.join('/');

    const urlsWithFullLayout = [
        // '/auth/bank-options',
        // '/auth/chama-options',
        // '/auth/sacco-options',
        // '/auth/individual-options',
        // '/auth/lending-options',
        '/auth/rental-options',
        // '/auth/bank-options',
        // '/auth/pending',
        // '/auth/authorize',
        // '/auth/options',
        // '/auth/join',
    ];
    const urlsWithCenteredlLayout = ['/auth/options', '/auth/join'];

    let isFullLayout =
        urlsWithFullLayout.includes(curr_path) ||
        urlsWithFullLayout.includes(extracted_path);
    let isCenteredLayout =
        urlsWithCenteredlLayout.includes(curr_path) ||
        urlsWithCenteredlLayout.includes(extracted_path);

    function getAsideTemplate() {
        return !isFullLayout ? (
            <div className="login-aside d-flex flex-row-auto">
                <div className="d-flex flex-row-fluid flex-column justify-content-between">
                    <div className="flex-column-fluid d-flex flex-column justify-content-center">
                        <div className={'main-logo'}>
                            <Link to="/">
                                <img
                                    src={`${toAbsoluteUrl(
                                        '/media/logos/logo.png',
                                    )}`}
                                    alt="realtors"
                                    style={{
                                        height: '32px',
                                        marginLeft: '-6px',
                                    }}
                                />
                            </Link>
                        </div>
                        <ProductsCarousel current={getParams?.for} />
                    </div>
                </div>
            </div>
        ) : null;
    }

    function getHeaderBackLink() {
        if (isFullLayout && !isCenteredLayout) {
            return (
                <div className="position-absolute top-0 left-0 text-right- mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center- py-5 px-10">
                    <Link
                        to="/auth"
                        className="font-weight-bold ml-2"
                        id="kt_login_signup"
                        title="Go back to log in"
                    >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/svg/icons/Navigation/Arrow-left.svg',
                                )}
                            />
                        </span>
                    </Link>
                </div>
            );
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className={
                        'login ' +
                        (isCenteredLayout ? 'login-2' : 'login-1') +
                        ' chamasoft-login login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white ' +
                        (isFullLayout ? ' bg-light' : '')
                    }
                    id="kt_login"
                >
                    {getAsideTemplate()}

                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden- ml-lg-10-">
                        {getHeaderBackLink()}

                        {/*begin::Content header*/}
                        <div
                            className="chamasoft-effect"
                            style={{
                                background: `url(${toAbsoluteUrl(
                                    '/media/bg/cs_auth_grad.svg',
                                )}) top center no-repeat rgba(255, 255, 255, 0)`,
                                backgroundSize: 'cover',
                            }}
                        ></div>
                        {/*end::Content header*/}
                        {/* <div className={"flex-column-auto flex-column flex-sm-row justify-content-between align-items-center my-5 " + (isFullLayout ? ' _full-layout d-none' : ' d-flex')}>
              <Link to="/">
                <img src={`${toAbsoluteUrl("/media/logos/chamasoft_new_logo.png")}`} alt="chamasoft" style={{ height: '36px', marginLeft: '-6px' }} />
              </Link>
            </div> */}
                        {/* begin::Content body */}
                        <div
                            className={
                                'd-flex flex-column-fluid ' +
                                (isCenteredLayout
                                    ? 'flex-center'
                                    : 'flex-center-') +
                                ' ' +
                                (isCenteredLayout
                                    ? 'align-items-start'
                                    : 'align-items-center') +
                                ' mt-30- mt-lg-0'
                            }
                        >
                            <Switch>
                                <ContentRoute
                                    path="/auth/login/:type"
                                    component={Login}
                                />

                                <ContentRoute
                                    path="/auth/signup"
                                    component={Registration}
                                />
                                <ContentRoute
                                    path="/auth/forgot-password/:type"
                                    component={ForgotPassword}
                                />
                                <ContentRoute
                                    path="/auth/verify-account/:hex"
                                    component={VerifyAccount}
                                />
                                <ContentRoute
                                    path="/auth/confirm-code"
                                    component={ConfirmCode}
                                />
                                <ContentRoute
                                    path="/auth/confirm-code/:type"
                                    component={ConfirmCode}
                                />
                                <ContentRoute
                                    path="/auth/bank-options"
                                    component={CsBank}
                                />
                                <ContentRoute
                                    path="/auth/chama-options"
                                    component={CsChama}
                                />
                                <ContentRoute
                                    path="/auth/sacco-options"
                                    component={CsSacco}
                                />
                                <ContentRoute
                                    path="/auth/individual-options"
                                    component={CsIndividual}
                                />
                                <ContentRoute
                                    path="/auth/lending-options"
                                    component={CsLending}
                                />
                                <ContentRoute
                                    path="/auth/rental-options"
                                    component={CsRental}
                                />
                                <ContentRoute
                                    path="/auth/reset-password/:token"
                                    component={ResetPassword}
                                />
                                <ContentRoute
                                    path="/auth/verify-email-request"
                                    component={VerifyEmail}
                                />
                                <ContentRoute
                                    path="/auth/verify-email/:token"
                                    component={VerifyEmail}
                                />
                                <ContentRoute
                                    path="/auth/confirm-rental-creation"
                                    component={ConfirmRentalCreation}
                                />
                                <ContentRoute
                                    path="/auth/pend"
                                    component={PendingVerification}
                                />
                                <ContentRoute
                                    path="/auth/confirm-rental-creation"
                                    component={ConfirmRentalCreation}
                                />
                                <ContentRoute
                                    path="/auth/options"
                                    component={Checkin}
                                />
                                <ContentRoute
                                    path="/auth/entity-type"
                                    component={EntityType}
                                />
                                <ContentRoute
                                    path="/auth/join/:hex"
                                    component={Join}
                                />
                                <ContentRoute
                                    path="/auth/authorize"
                                    component={VerifyInstitution}
                                />
                                <Redirect
                                    from={`/auth` || `auth/login`}
                                    exact={true}
                                    to="/auth/login/phone"
                                />
                                <Redirect to="/auth/login/phone" />
                            </Switch>
                        </div>
                        {/*end::Content body*/}

                        {/* begin:: Footer */}
                        <div
                            className={
                                'mt-5 py-5 ' +
                                (isFullLayout
                                    ? isCenteredLayout
                                        ? ' d-flex flex-center flex-column'
                                        : ' _full-layout'
                                    : '')
                            }
                        >
                            <div className="d-flex flex-column-auto flex-column flex-sm-row justify-content-start align-items-start">
                                <small>
                                    <Link
                                        to={`${toAbsoluteUrl('/policy')}`}
                                        target="_blank"
                                        className="text-primary text-hover-primary mr-5 pr-lg-5"
                                    >
                                        <FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" />
                                    </Link>
                                </small>
                                <small>
                                    <a
                                        href="https://chamasoft.com/terms-and-conditions/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-hover-primary mr-5 pr-lg-5"
                                    >
                                        <FormattedMessage id="AUTH.FOOTER.LINKS.TERMS" />
                                    </a>
                                </small>
                                <small>
                                    <a
                                        href="https://calendly.com/chamasoft-meetings/support-meeting"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-hover-primary mr-5 pr-lg-5"
                                    >
                                        <FormattedMessage id="AUTH.FOOTER.LINKS.SCHEDULE" />
                                    </a>
                                </small>
                                <small>
                                    <a
                                        href="https://chamasoft.com/contact"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-hover-primary mr-5"
                                    >
                                        <FormattedMessage id="AUTH.FOOTER.LINKS.CONTACT" />
                                    </a>
                                </small>
                            </div>
                            <div className="d-flex flex-column-auto justify-content-between mt-5">
                                <div className="font-weight-bold- text-muted">
                                    <small>
                                        &copy; {current_year}{' '}
                                        {appConfigs.org_name}
                                    </small>
                                </div>
                            </div>
                        </div>
                        {/* end:: Footer */}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    );
}
