import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { newPassword } from '../_redux/authCrud';
import { useParams } from 'react-router';

const initialValues = {
    password: '',
};

function ResetPassword(props) {
    let { token } = useParams();
    const { intl } = props;
    const [showPassword, setshowPasswordValue] = useState(false);
    const alert = props.history.location.state?.alert;
    const [isRequested, setIsRequested] = useState(false);
    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(
                6,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Password', min: '6' },
                ),
            )
            .max(
                50,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Password', max: '50' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Password' },
                ),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            intl.formatMessage(
                { id: 'AUTH.VALIDATION.PASSWORD_MISMATCH' },
                // { id: 'The password and its confirm are not the same' },
                { name: 'Confirm Password' },
            ),
        ),
    });
    const handleClickShowPassword = () => {
        setshowPasswordValue(!showPassword);
    };
    // const getInputClasses = fieldname => {
    //     if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //         return 'is-invalid';
    //     }

    //     if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //         return 'is-valid';
    //     }

    //     return '';
    // };

    const formik = useFormik({
        initialValues,
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var navigateTo = '';
            var navigateAlert = {};

            setSubmitting(true);
            newPassword(values.password, token)
                .then(resp => {
                    console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id:
                                    'AUTH.RESET.PASSWORD.PASSWORD_RESET_SUCCESSFUL',
                            }),
                            type: 'success',
                        };
                    } else {
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                            }),
                            type: 'danger',
                        };
                    }
                    navigateTo = '/auth/login';
                    // setIsRequested(true);
                })
                .catch(() => {
                    setIsRequested(false);
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({
                            id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                        }),
                    );
                })
                .finally(() => {
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                        });
                });
        },
    });
    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return true;
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return false;
        }

        return false;
    };

    return (
        <>
            {isRequested && <Redirect to="/auth" />}
            {!isRequested && (
                <div
                    className="login-form login-forgot"
                    style={{ display: 'block' }}
                >
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            <FormattedMessage id="AUTH.RESET.PASSWORD" />
                        </h3>
                        <div className="text-muted font-weight-bold">
                            <FormattedMessage id="AUTH.RESET.PASSWORD.DESCRIPTION" />
                        </div>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status ? (
                            <div
                                className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {formik.status.msg}
                                </div>
                            </div>
                        ) : alert ? (
                            <div
                                className={`mb-10 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}
                        {/* start: Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <TextField
                                error={inputHasError('password')}
                                label="New password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                {...formik.getFieldProps('password')}
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block w-75">
                                        {formik.errors.password}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Password */}

                        {/* begin: Confirm Password */}
                        <div className="form-group fv-plugins-icon-container">
                            <TextField
                                error={inputHasError('confirmPassword')}
                                label="Confirm New password"
                                name="confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="confirmPassword"
                                {...formik.getFieldProps('confirmPassword')}
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.touched.confirmPassword &&
                            formik.errors.confirmPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block w-100">
                                        {formik.errors.confirmPassword}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* end: Confirm Password */}
                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary btn-block font-weight-bold px-9 py-4 my-3 mx-4-"
                                disabled={formik.isSubmitting}
                            >
                                <FormattedMessage id="AUTH.RESET.PASSWORD" />
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
