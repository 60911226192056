import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { verifyAccount } from '../_redux/authCrud';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { Redirect, useParams } from 'react-router';
import { appConfigs } from '../../../../_chamasoft/_helpers';

const initialValues = {
    code: '',
};

function VerifyAccount(props) {
    document.title = 'Verify account — ' + appConfigs.app_name;
    let { hex } = useParams();
    const [loading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const isValid = () => {
        if (!hex || hex === undefined || hex === null || hex === '')
            return false;
        let _hex = localStorage.getItem('accountVerifyHex');
        if (!_hex || _hex === undefined || _hex === null || _hex === '')
            return false;
        if (hex === _hex) return true;
        else return false;
    };
    const { intl } = props;
    var alert = props.history.location.state?.alert;
    const VerifyAccountSchema = Yup.object().shape({
        code: Yup.string()
            .min(
                4,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                    { name: 'Code', min: '4' },
                ),
            )
            .max(
                10,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Code', max: '10' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Code' },
                ),
            ),
    });

    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return true;
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: VerifyAccountSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var token = '';
            var navigateTo = '';
            var navigateAlert = {};
            setSubmitting(true);
            setLoading(true);
            verifyAccount(values.code)
                .then(resp => {
                    // console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        token = resp.data.user.access_token;
                        localStorage.removeItem('accountVerifyHex');
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.VERIFY.ACCOUNT.SUCCESS',
                            }),
                            type: 'success',
                        };
                        setStatus({
                            msg: intl.formatMessage({
                                id: 'AUTH.VERIFY.ACCOUNT.SUCCESS',
                            }),
                            type: 'success',
                        });
                        setIsVerified(true);
                        // props.login(token);
                        navigateTo = '/auth/entity-type';
                    } else {
                        localStorage.removeItem('accountVerifyHex');
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.VERIFY.ACCOUNT.ERROR',
                            }),
                            type: 'danger',
                        };
                        navigateTo = '/auth/login';
                        setIsVerified(false);
                    }
                    // }
                })
                .catch(() => {
                    setSubmitting(false);
                    setLoading(false);
                    setStatus(
                        intl.formatMessage({ id: 'AUTH.VERIFY.ACCOUNT.ERROR' }),
                    );
                    setIsVerified(false);
                })
                .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                            token: token,
                        });
                });
        },
    });

    return (
        <>
            {!isValid() && <Redirect to="/auth" />}
            {isValid() && (
                <div
                    className="login-form login-forgot"
                    style={{ display: 'block' }}
                >
                    {/* begin::Head */}
                    <div className="text-left mb-5 mb-lg-20-">
                        <h3 className="font-weight-bolder mb-0">
                            <FormattedMessage id="AUTH.VERIFY.ACCOUNT" />
                        </h3>
                        <p className="text-dark-50 font-weight-normal">
                            <FormattedMessage id="AUTH.VERIFY.ACCOUNT.INTRO" />
                        </p>
                    </div>
                    {/* end::Head */}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {formik.status.msg}
                                </div>
                            </div>
                        ) : alert ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}

                        <div className="form-group fv-plugins-icon-container mb-5">
                            <TextField
                                error={inputHasError('code')}
                                label="Confirmation code"
                                name="code"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps('code')}
                                margin="normal"
                            />
                            {formik.touched.code && formik.errors.code ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.code}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center pt-5">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                                disabled={formik.isSubmitting}
                            >
                                <span>
                                    <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                                </span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                            <Link
                                to="/auth/login"
                                className={`btn btn-outline-secondary btn-pill btn-sm font-weight-bold px-15 py-4 ${
                                    isVerified === true ? '' : 'disabled'
                                }`}
                            >
                                <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                            </Link>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(VerifyAccount));
