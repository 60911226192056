import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import * as auth from '../../_redux/authRedux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './carousel.scss';
import { toAbsoluteUrl } from '../../../../../_chamasoft/_helpers';

const products = [
    // {
    //     slug: 'chamas',
    //     title: 'Investment groups',
    //     description:
    //         'Partner with us today & offer group services better as we handle your financial administration.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/chama.jpg',
    // },
    // {
    //     slug: 'banks',
    //     title: 'Financial institutions',
    //     description:
    //         'Chamasoft has a bank-managed user interface that provides real time full reports.',
    //     more: 'https://chamasoft.com/chamasoft-for-financial-institutions',
    //     bgImage: '/media/bg/finance.jpg',
    // },
    // {
    //     slug: 'lending',
    //     title: 'Lending',
    //     description:
    //         'Onboard groups remotely, open accounts, start depositing, and get recommendations on lending decisions based on group data.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/lending.jpg',
    // },
    {
        slug: 'rentals',
        title: 'Rental Management',
        description:
            'Partner with us today & do rental management better as we handle your rental units administration.',
        more: '/overview',
        bgImage: '/media/bg/rentals.jpg',
    },
    // {
    //     slug: 'smes',
    //     title: 'Small & Medium-sized Enterprises',
    //     description:
    //         'Chamasoft helps you understand the risk and return on investment on customer deposits.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/sme.jpg',
    // },
    // {
    //     slug: 'saccos',
    //     title: 'SACCOs',
    //     description:
    //         'Chamasoft helps you understand the risk and return on investment on customer deposits.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/sacco.jpg',
    // },
    // {
    //     slug: 'personal',
    //     title: 'Personal use',
    //     description:
    //         'Get insights to the bank on behavior on their savings group and investment club partners.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/personal.jpg',
    // },
    // {
    //     slug: 'crm',
    //     title: 'Customer Relationship Management',
    //     description:
    //         'We offer you various channels through which you can easily communicate with your members.',
    //     more: 'https://chamasoft.com',
    //     bgImage: '/media/bg/crm.jpg',
    // },
];

const ProductsCarousel = ({ current }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const animate = idx => {
        setCurrentSlide(idx);
        const slide = document.getElementById('slide-info-' + idx) || undefined;
        if (slide) {
            resetAnimation(idx);
            slide.querySelector('h5').classList.add('animate', 'slideIn');
            slide.querySelector('p').classList.add('animate', 'slideIn');
            slide.querySelector('a').classList.add('animate', 'slideIn');
            if (idx > currentSlide) resetAnimation(idx - 1);
            if (idx < currentSlide) resetAnimation(idx + 1);
        }
    };

    const resetAnimation = idx => {
        const slide = document.getElementById('slide-info-' + idx) || undefined;
        if (slide) {
            if (slide.querySelector('h5').classList.contains('animate'))
                slide
                    .querySelector('h5')
                    .classList.remove('animate', 'slideIn');
            if (slide.querySelector('p').classList.contains('animate'))
                slide.querySelector('p').classList.remove('animate', 'slideIn');
            if (slide.querySelector('a').classList.contains('animate'))
                slide.querySelector('a').classList.remove('animate', 'slideIn');
        }
    };
    useEffect(() => {
        if (current) {
            const idx = products.findIndex(p => p.slug === current);
            setCurrentSlide(idx);
            animate(idx);
        } else {
            animate(currentSlide);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    return (
        <Carousel
            className="products-carousel"
            showThumbs={false}
            autoPlay={false}
            showStatus={false}
            selectedItem={currentSlide}
            useKeyboardArrows={true}
            onChange={(idx, slide) => animate(idx)}
            renderArrowNext={(clickHandler, hasNext, label) => {
                return (
                    <div
                        className={'nxt arrow' + (!hasNext ? ' disabled' : '')}
                        onClick={clickHandler}
                    >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                );
            }}
            renderArrowPrev={(clickHandler, hasPrev, label) => {
                return (
                    <div
                        className={'prv arrow' + (!hasPrev ? ' disabled' : '')}
                        onClick={clickHandler}
                    >
                        <i className="fa fa-chevron-left"></i>
                    </div>
                );
            }}
        >
            {products.map((product, c) => {
                return (
                    <div
                        key={product?.slug}
                        className="slide"
                        style={{
                            background: `url('${toAbsoluteUrl(
                                product?.bgImage,
                            )}') center no-repeat #00abf2`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="info-shell" id={'slide-info-' + c}>
                            <h5 className="delay-2">{product?.title}</h5>
                            <p className="delay-6">{product?.description}</p>
                            {product?.more && (
                                <a
                                    className="btn btn-outline btn-pill btn-sm font-weight-bold px-10 text-white text-hover-primary py-3 delay-10"
                                    href={`${product?.more}`}
                                    // target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Learn more
                                </a>
                            )}
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};
export default injectIntl(connect(null, auth.actions)(ProductsCarousel));
