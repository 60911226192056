import axios from 'axios';
import { appConfigs } from '../../../../_chamasoft/_helpers';

export function login(identity, password) {
    // console.log('API Path: ', appConfigs.api_url + 'users/login');
    return axios.post(appConfigs.api_url + 'users/login', {
        identity,
        password,
    });
}

export function register(
    email,
    fullname,
    firstname,
    middlename,
    lastname,
    password,
    phone,
) {
    return axios.post(appConfigs.api_url + 'users/signup', {
        email,
        fullname,
        firstname,
        middlename,
        lastname,
        password,
        phone,
    });
}

export function resetPassword(type, identity) {
    return axios.post(appConfigs.api_url + 'users/reset-password', {
        type,
        identity,
    });
}

export function verifyCode(code) {
    return axios.post(appConfigs.api_url + 'users/verify-code', { code });
}

export function selectEntity(entity, code) {
    return axios.post(appConfigs.api_url + 'users/select-profile', {
        profile: entity,
        accessToken: code,
    });
}

export function verifyAccount(code) {
    return axios.post(appConfigs.api_url + 'users/verify-account', { code });
}

export function bankSignup(
    accessToken,
    bank_id,
    bank_name,
    email,
    country,
    phone,
) {
    return axios.post(appConfigs.api_url + 'banks/new-bank', {
        accessToken,
        bank_id,
        bank_name,
        email,
        country,
        phone,
    });
}

export function ngoSignup(
    accessToken,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'ngos/new-ngo', {
        accessToken,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function saccoSignup(
    accessToken,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'saccos/new-sacco', {
        accessToken,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function chamaSignup(
    accessToken,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'chamas/new-chama', {
        accessToken,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function individualSignup(
    accessToken,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'individuals/new-individual', {
        accessToken,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function lendingSignup(
    accessToken,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'lending/new-lending', {
        accessToken,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function rentalSignup(
    accessToken,
    role,
    name,
    country,
    email,
    website,
    address,
    phone,
) {
    return axios.post(appConfigs.api_url + 'rentals/new-rental', {
        accessToken,
        role,
        name,
        country,
        email,
        website,
        address,
        phone,
    });
}

export function getCountries() {
    return axios.post(appConfigs.api_url + 'get-countries');
}

//export function to add new user in rental app
export function authorizeNewUser(user) {
    return axios.post(appConfigs.rental_home_api_url + 'users/create', {
        user,
    });
}

export function getBanks(country_id) {
    return axios.post(appConfigs.api_url + 'banks/get-banks', { country_id });
}

export function getRoles(accessToken, profile_type, institution_id) {
    return axios.post(appConfigs.api_url + 'roles', {
        accessToken,
        institution_id,
        profile_type,
    });
}

export function validateBank(accessToken, bank_id) {
    return axios.post(appConfigs.api_url + 'banks/validate-bank', {
        accessToken,
        bank_id,
    });
}

export function createRole(_role) {
    return axios.post(appConfigs.api_url + 'roles/create-role', _role);
}

export function newPassword(password, reset_code) {
    return axios.post(appConfigs.api_url + 'users/new-password', {
        password,
        reset_code,
    });
}

export function verifyEmail(code) {
    return axios.post(appConfigs.api_url + 'users/verify-email', { code });
}

export function resendVerification(email) {
    return axios.post(appConfigs.api_url + 'users/resend-verification', {
        email,
    });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.post(appConfigs.api_url + 'users/get-user-by-token');
}

export function authorizeInstitution(type, token, action) {
    if (type === 'lending') {
        return axios.post(
            appConfigs.api_url + 'lending/authorize-institution',
            {
                token,
                action,
            },
        );
    } else {
        return axios.post(appConfigs.api_url + type + 's/authorize-' + type, {
            token,
            action,
        });
    }
}

// //export function to add new user in rental app
// export function authorizeNewUser(user) {
//   return axios.post(appConfigs.rental_home_api_url + 'users/create', { user });
// }

export function getCheckin(accessToken) {
    return axios.post(appConfigs.api_url + 'users/checkin-data', {
        accessToken,
    });
}

export function verifyJoinRequest(hex) {
    return axios.post(appConfigs.api_url + 'users/verify-join-request', {
        hex,
    });
}

export function joinRequest(hex, response, user, register) {
    // register >>> 1: Accept & register, 0: Accept & add profile to user
    return axios.post(appConfigs.api_url + 'users/process-join-request', {
        hex,
        response,
        user,
        register,
    });
}

export function checkinUser(accessToken, profile) {
    return axios.post(appConfigs.api_url + 'users/checkin', {
        accessToken,
        profile,
    });
}
