/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownItemToggler } from '../../../../_partials/dropdowns';

export function UserProfileDropdown({ user, showLogout = true }) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            light:
                objectPath.get(
                    uiService.config,
                    'extras.user.dropdown.style',
                ) === 'light',
        };
    }, [uiService]);

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownItemToggler}
                id="dropdown-toggle-user-profile"
            >
                <div
                    className={
                        'btn btn-icon btn-clean d-flex align-items-center btn-lg px-md-2 w-md-auto'
                    }
                >
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                        Hi,
                    </span>
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                        {user?.first_name}
                    </span>
                    <span className="symbol symbol-35 symbol-light-success">
                        <span className="symbol-label font-size-h5 font-weight-bold">
                            {user?.first_name && user?.last_name
                                ? user?.first_name[0] + user?.last_name[0]
                                : 'CS'}
                        </span>
                    </span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                <>
                    {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                    {layoutProps.light && (
                        <>
                            <div className="d-flex align-items-center px-8 rounded-top py-4">
                                {/* <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
              </div> */}
                                <div className="text-dark-50 m-0 flex-grow-1 mr-3 font-size-h5 font-weight-boldest">
                                    {user?.fullname}
                                    <small
                                        className="d-block font-weight-normal"
                                        style={{ fontSize: '10px' }}
                                    >
                                        {user?.phone}
                                    </small>
                                </div>
                                <span className="label label-light-success label-lg font-weight-bold label-inline d-none">
                                    3 messages
                                </span>
                            </div>
                            <div className="separator separator-solid"></div>
                        </>
                    )}

                    {!layoutProps.light && (
                        <div
                            className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                    '/media/misc/bg-1.jpg',
                                )})`,
                            }}
                        >
                            <div className="symbol bg-white-o-15 mr-3">
                                <span className="symbol-label text-success font-weight-bold font-size-h4">
                                    S
                                </span>
                                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                            </div>
                            <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                                {user?.fullname}
                            </div>
                            <span className="label label-success label-lg font-weight-bold label-inline d-none">
                                3 messages
                            </span>
                        </div>
                    )}
                </>

                <div className="navi navi-spacer-x-0 pt-5">
                    <Link
                        to={{
                            pathname: '/auth/entity-type',
                            state: { token: user?.access_token },
                        }}
                        className="navi-item px-8"
                    >
                        <div className="navi-link">
                            <div className="navi-icon mr-2">
                                <i className="flaticon2-plus text-success" />
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">New</div>
                                <div className="text-muted small">
                                    Add Rental organization &amp; more
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="navi-separator mt-3"></div>

                    <a className="navi-item px-8">
                        <div className="navi-link">
                            <div className="navi-icon mr-2">
                                <i className="flaticon2-information text-success" />
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Get help</div>
                                <div className="text-muted small">
                                    Visit help documents
                                </div>
                            </div>
                        </div>
                    </a>
                    <a className="navi-item px-8">
                        <div className="navi-link">
                            <div className="navi-icon mr-2">
                                <i className="flaticon2-files-and-folders text-success" />
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Blog</div>
                                <div className="text-muted small">
                                    Visit digital vision EA blog
                                </div>
                            </div>
                        </div>
                    </a>

                    {showLogout && <div className="navi-separator mt-3"></div>}

                    {showLogout && (
                        <div className="navi-footer  px-8 py-5">
                            <Link
                                to="/logout"
                                className="btn btn-light-danger font-weight-bold"
                            >
                                Sign Out
                            </Link>
                        </div>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
