import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { login } from '../_redux/authCrud';
// import { toAbsoluteUrl } from '../../../../_chamasoft/_helpers';
import { TextField, InputAdornment, IconButton, Fade } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { appConfigs } from '../../../../_chamasoft/_helpers';
import MuiPhoneInput from 'material-ui-phone-number';
import queryString from 'query-string';

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    usePhone: '',
    email: '',
    phone: '',
    password: '',
};

function Login(props) {
    document.title = 'Login — ' + appConfigs.app_name;
    const getParams = queryString.parse(window.location.search);
    const fiLayout = getParams?.for === 'fi' || false;
    let [type, setType] = useState('phone');
    // console.log("type", type);
    const params = useParams();
    // console.log('use params', useParams());
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
    const updateNetwork = () => {
        setNetwork(window.navigator.onLine);
    };
    function checkNetwork() {
        return isOnline;
    }
    // console.log('userNetwork', checkNetwork());
    const [loading, setLoading] = useState(false);
    const [showPassword, setshowPasswordValue] = useState(false);
    const [_phone, setPhone] = useState(initialValues.phone);
    const [_email, setEmail] = useState(initialValues.email);
    const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    const alert = props.history.location.state?.alert;
    const [resend, setResend] = useState(false);
    const { intl } = props;

    useEffect(() => {
        // console.log('params.type', params.type);
        if (params.type) {
            setType(params.type);
        } else {
            setType('phone');
        }

        window.addEventListener('online', updateNetwork);
        window.addEventListener('offline', updateNetwork);
        return () => {
            window.removeEventListener('online', updateNetwork);
            window.removeEventListener('offline', updateNetwork);
        };

        // if()
        // if(localStorage.getItem('type')){
        //     setType(localStorage.getItem('type'));
        // }else{
        //     localStorage.setItem('type', "phone");
        // }
    }, [params]);

    initialValues.usePhone = type === 'phone';
    const LoginSchema = Yup.object().shape({
        usePhone: Yup.boolean(),
        phone: Yup.string().when('usePhone', {
            is: true,
            then: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required(
                    intl.formatMessage(
                        { id: 'AUTH.VALIDATION.REQUIRED' },
                        { name: 'Phone' },
                    ),
                ),
        }),

        email: Yup.string().when('usePhone', {
            is: false,
            then: Yup.string()
                .email(
                    intl.formatMessage(
                        { id: 'AUTH.VALIDATION.INVALID_EMAIL_FIELD' },
                        { name: 'Email' },
                    ),
                )
                .required(
                    intl.formatMessage(
                        { id: 'AUTH.VALIDATION.REQUIRED' },
                        { name: 'Email' },
                    ),
                ),
        }),

        password: Yup.string()
            // .min(6, intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH",}, {name: 'Password', min: '6'}))
            .max(
                50,
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                    { name: 'Password', max: '50' },
                ),
            )
            .required(
                intl.formatMessage(
                    { id: 'AUTH.VALIDATION.REQUIRED' },
                    { name: 'Password' },
                ),
            ),
    });

    const handleClickShowPassword = () => {
        setshowPasswordValue(!showPassword);
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const enableResend = () => {
        setResend(true);
    };

    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return true;
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return false;
        }

        return false;
    };

    const randomStr = length => {
        var result = '';
        var characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength),
            );
        }
        return result;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var user = {};
            var navigateTo = '';
            var navigateAlert = {};
            let verStr = randomStr(40);
            // console.log('values', values);

            function handleOffline() {
                setStatus({
                    msg: intl.formatMessage({
                        id: 'AUTH.VALIDATION.NO_INTERNET',
                    }),
                    type: 'danger',
                });
                disableLoading();
                setSubmitting(false);
            }

            enableLoading();
            // console.log('email:', _email);
            checkNetwork() === true
                ? login(
                      type === 'phone' ? values.phone : values.email,
                      values.password,
                  )
                      .then(resp => {
                          // console.log(resp.data);
                          user = resp.data.user;

                          if (resp.data.result_code === 1) {
                              setStatus({
                                  msg: intl.formatMessage(
                                      { id: 'AUTH.LOGIN.SUCCESS' },
                                      { name: resp.data.user.first_name },
                                  ),
                                  type: 'success',
                              });
                              // props.login(user?.access_token);
                              navigateTo = '/auth/options';
                          } else if (resp.data.result_code === 0) {
                              setStatus({
                                  msg: intl.formatMessage({
                                      id: 'AUTH.VALIDATION.INVALID_LOGIN',
                                  }),
                                  type: 'danger',
                              });
                          } else if (resp.data.result_code === 3) {
                              navigateAlert = {
                                  msg: intl.formatMessage(
                                      { id: 'AUTH.LOGIN.NOT_VERIFIED' },
                                      { phone: resp.data.user.phone },
                                  ),
                                  type: 'info',
                              };
                              navigateTo = '/auth/verify-account';
                              localStorage.setItem('accountVerifyHex', verStr);
                              navigateTo = '/auth/verify-account/' + verStr;
                              enableResend();
                              setStatus({
                                  msg: intl.formatMessage(
                                      { id: 'AUTH.LOGIN.NOT_VERIFIED' },
                                      { phone: resp.data.user.phone },
                                  ),
                                  type: 'danger',
                              });
                          } else {
                              setStatus({
                                  msg: intl.formatMessage({
                                      id: 'AUTH.VALIDATION.INVALID_LOGIN',
                                  }),
                                  type: 'danger',
                              });
                          }
                      })
                      .catch(() => {
                          disableLoading();
                          setSubmitting(false);
                          setStatus({
                              msg: intl.formatMessage({
                                  id: 'AUTH.VALIDATION.INVALID_LOGIN',
                              }),
                              type: 'danger',
                          });
                      })
                      .finally(() => {
                          disableLoading();
                          setSubmitting(false);
                          if (navigateTo !== '')
                              props.history.replace(navigateTo, {
                                  alert: navigateAlert,
                                  user: user,
                              });
                      })
                : handleOffline();
        },
    });

    const resendVerificationEmail = _resend => {
        return _resend ? (
            <span>
                &nbsp;
                <span className="text-primary font-weight-bold">
                    <FormattedMessage id="AUTH.VERIFY.EMAIL.RESEND" />
                </span>
                <Link
                    to="/auth/verify-email-request"
                    className="text-primary font-weight-boldest text-hover-info ml-2"
                    id="kt_login_forgot"
                >
                    <FormattedMessage id="AUTH.GENERAL.RESEND" />
                </Link>
            </span>
        ) : (
            ''
        );
    };

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div
                className={`text-left mb-5 ${
                    type === 'phone' ? 'mb-lg-10' : ''
                }`}
            >
                <h3 className="font-weight-bolder mb-0">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-dark-50 font-weight-normal">
                    <FormattedMessage id="AUTH.LOGIN.DESCR" />
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status ? (
                    <div
                        className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                    >
                        <div className="alert-text font-weight-bold">
                            {formik.status.msg}
                            {resendVerificationEmail(resend)}
                        </div>
                    </div>
                ) : alert ? (
                    <div
                        className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                    >
                        <div className="alert-text font-weight-bold">
                            {alert.msg}
                        </div>
                    </div>
                ) : null}

                {type === 'phone' ? (
                    <div className="form-group fv-plugins-icon-container mb-1">
                        <input
                            type="hidden"
                            name="phone"
                            value={_phone}
                            {...formik.getFieldProps('phone')}
                        />
                        <MuiPhoneInput
                            required
                            error={inputHasError('phone')}
                            label="Phone"
                            name="_phone"
                            value={_phone}
                            countryCodeEditable={true}
                            inputClass={'cs-phone-inp'}
                            defaultCountry="ke"
                            autoComplete="off"
                            onChange={setPhone}
                            onKeyUp={e =>
                                formik.setFieldValue('phone', e.target.value)
                            }
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.phone}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <Fade
                        in={type === 'email'}
                        style={{ vertical: 'bottom', horizontal: 'center' }}
                        {...(type ? { timeout: 1000 } : {})}
                    >
                        <div className="form-group fv-plugins-icon-container mb-1">
                            <TextField
                                required
                                error={inputHasError('email')}
                                label="Email"
                                name="email"
                                type={'email'}
                                value={_email}
                                autoComplete="email"
                                onChange={setEmail}
                                onKeyUp={e =>
                                    formik.setFieldValue(
                                        'email',
                                        e.target.value,
                                    )
                                }
                                {...formik.getFieldProps('email')}
                                margin="normal"
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.email}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Fade>
                )}
                <div className="form-group fv-plugins-icon-container mb-4-">
                    <TextField
                        required
                        error={inputHasError('password')}
                        label="Password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="password"
                        {...formik.getFieldProps('password')}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.password}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <div className="form-group text-lg-left mb-4-">
                        <span>
                            {/* <FormattedMessage id="AUTH.GENERAL.LOGIN.WITH_TAG" /> */}
                            <Link
                                to={
                                    type === 'phone'
                                        ? fiLayout
                                            ? '/auth/login/email?for=fi'
                                            : '/auth/login/email'
                                        : fiLayout
                                        ? '/auth/login/phone?for=fi'
                                        : '/auth/login/phone'
                                }
                                className="text-dark-50 font-weight-boldest text-hover-primary ml-2"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage
                                    id="AUTH.LOGIN.WITH.TYPE.ACTION"
                                    values={{
                                        type:
                                            type === 'phone'
                                                ? 'email'
                                                : 'phone',
                                    }}
                                />
                            </Link>
                        </span>
                    </div>
                    <div className="form-group text-lg-right mb-4-">
                        <span>
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_TAG" />
                            <Link
                                to={
                                    fiLayout
                                        ? '/auth/forgot-password/phone?for=fi'
                                        : '/auth/forgot-password/phone'
                                }
                                className="text-dark-50 font-weight-boldest text-hover-primary my- ml-2"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10`}
                    >
                        <span>
                            <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                        </span>
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </button>
                    <Link
                        to={fiLayout ? '/auth/signup?for=fi' : '/auth/signup'}
                        className="btn btn-outline btn-pill btn-sm font-weight-bold px-15 py-4"
                    >
                        <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                    </Link>
                </div>
                {/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center pt-5">
                    <span className="text-dark-50">
                        <FormattedMessage id="AUTH.GENERAL.LOGIN.SOCIAL_LOGIN.INTRO" />
                    </span>
                </div> */}
                {/* <div className="form-group d-flex flex-wrap justify-content-start align-items-center">
                    <Link
                        to="/auth/login"
                        className="text-primary font-weight-bold mr-7"
                        id="kt_login_forgot"
                    >
                        <img
                            src={`${toAbsoluteUrl(
                                '/media/svg/social-icons/google.svg',
                            )}`}
                            alt="gl"
                            style={{
                                height: '16px',
                                marginTop: '-2px',
                                marginRight: '4px',
                            }}
                        />
                        <FormattedMessage id="AUTH.GENERAL.LOGIN.SOCIAL_LOGIN.GOOGLE" />
                    </Link>
                    <Link
                        to="/auth/login"
                        className="text-primary font-weight-bold mr-7"
                        id="kt_login_forgot"
                    >
                        <img
                            src={`${toAbsoluteUrl(
                                '/media/svg/social-icons/twitter.svg',
                            )}`}
                            alt="tw"
                            style={{
                                height: '16px',
                                marginTop: '-2px',
                                marginRight: '4px',
                            }}
                        />
                        <FormattedMessage id="AUTH.GENERAL.LOGIN.SOCIAL_LOGIN.TWITTER" />
                    </Link>
                    <Link
                        to="/auth/login"
                        className="text-primary font-weight-bold"
                        id="kt_login_forgot"
                    >
                        <img
                            src={`${toAbsoluteUrl(
                                '/media/svg/social-icons/facebook-circular.svg',
                            )}`}
                            alt="fb"
                            style={{
                                height: '16px',
                                marginTop: '-2px',
                                marginRight: '4px',
                            }}
                        />
                        <FormattedMessage id="AUTH.GENERAL.LOGIN.SOCIAL_LOGIN.FACEBOOK" />
                    </Link>
                </div> */}
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
