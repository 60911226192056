import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as auth from '../modules/Auth/_redux/authRedux';
import { getProfileURL, getProfile } from '../../_chamasoft/_helpers';

export const AuthRedirect = () => {
    document.title = 'Redirecting...';

    const { user } = useSelector(
        state => ({
            user: state.auth.user,
        }),
        shallowEqual,
    );
    const dispatch = useDispatch();
    // console.log(user);
    if (!user) {
        dispatch(auth.actions.logout());
        // return;
    }

    const url = getProfileURL(user.profiles.active.profile);

    if (window.location.host.includes('localhost')) {
        document.title = 'Chamasoft redirect';
        return (
            <>
                <p style={{ margin: '20px' }}>
                    You're logged in, on <strong>{window.location.host}</strong>
                </p>
                <p style={{ margin: '0 20px 20px 20px' }}>
                    <a
                        href={url + '/auth/verify-user/' + user.redirect_string}
                        className={
                            'btn btn-primary btn-sm px-5 ' +
                            (user.redirect_string && user.redirect_string !== ''
                                ? ''
                                : 'disabled')
                        }
                    >
                        Go to{' '}
                        <strong>
                            {getProfile(user.profiles.active.profile)}
                        </strong>
                    </a>
                    <a
                        href="/logout"
                        className="btn btn-danger btn-sm px-5 ml-5"
                    >
                        Log out
                    </a>
                </p>
            </>
        );
    }

    setTimeout(() => {
        window.location.href =
            url + '/auth/verify-user/' + user.redirect_string;
    }, 1000);

    // localStorage.removeItem('_back');

    return <p style={{ margin: '20px' }}>Redirecting, please wait...</p>;
};
