import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_chamasoft/layout';
import * as auth from '../_redux/authRedux';

class Logout extends Component {
    componentDidMount() {
        // console.log('we are in logout page');
        this.props.logout();
    }

    render() {
        var url = new URL(window.location.href);
        var redirect_to = url.searchParams.get('back');
        // console.log('redirect to:', redirect_to);
        // console.log('this.props:', this.props);
        if (redirect_to) {
            localStorage.setItem('_back', decodeURI(redirect_to));
        }
        // console.log(decodeURI(redirect_to));
        const { hasAuthToken } = this.props;
        return hasAuthToken ? (
            <LayoutSplashScreen />
        ) : (
            <Redirect to="/auth/login/phone" />
        );
    }
}

export default connect(
    ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
    auth.actions,
)(Logout);
