/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/Error/ErrorsPage';
import LandingPage from './modules/Landing/LandingPage';
import TermsConditionsAndPolicy from './modules/TermsConditionsAndPolicy/TermsConditionsAndPolicy';

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual,
    );

    return (
        <Switch>
            <Route path="/error" component={ErrorsPage} />
            <Route path="/overview" component={LandingPage} />
            <Route path="/terms" component={TermsConditionsAndPolicy} />
            <Route path="/policy" component={TermsConditionsAndPolicy} />
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/logout" component={Logout} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <>
                    <Route path="/terms" component={TermsConditionsAndPolicy} />
                    <Route
                        path="/policy"
                        component={TermsConditionsAndPolicy}
                    />
                    <Redirect to="/auth/login/:type" />
                </>
            ) : (
                <BasePage />
            )}
        </Switch>
    );
}
