import React from 'react';
import { toAbsoluteUrl } from '../../../_chamasoft/_helpers';
import '../../../_chamasoft/_assets/sass/pages/error/error-5.scss';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

const itemData = [
    {
        img: toAbsoluteUrl('/media/captures/capture1.PNG'),
        title: 'Dashboard',
        rows: 2,
        cols: 2,
    },
    {
        img: toAbsoluteUrl('/media/captures/capture2.PNG'),
        title: 'Reports',
    },
    {
        img: toAbsoluteUrl('/media/captures/capture3.PNG'),
        title: 'Onboarding',
    },
];
export function PrivacyPolicy() {
    const classes = useStyles();
    // const bull = <span className={classes.bullet}>•</span>;

    return (
        <div className="d-flex flex-column flex-root">
            {/* Header */}
            <div className="Header bg-white px-5 py-2 w-full">
                <div className="container d-flex justify-content-between">
                    <div className="Header__logo d-flex align-items-center">
                        <Link to="/">
                            <img
                                src={`${toAbsoluteUrl(
                                    '/media/logos/logo.png',
                                )}`}
                                alt="realtors"
                                style={{
                                    height: '32px',
                                }}
                            />
                        </Link>
                    </div>
                    <div className="Header__nav align-items-end">
                        <Link to="/auth/login">
                            <Button
                                color="secondary"
                                className={classes.button}
                            >
                                Login
                            </Button>
                        </Link>
                    </div>
                </div>
                <hr />
            </div>
            {/* Banner */}
            <div className="container-fluid bg-white d-flex flex-column flex-row-fluid align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="text-dark font-weight-bold mb-7">
                                Realtors Rental Management System
                            </h1>
                            <p className="text-dark-50 font-size-lg mb-7">
                                A rental management solution which enables
                                property owners & property management agencies
                                to manage their property units & tenants.
                            </p>
                            <p className="text-dark-50 font-size-lg mb-7">
                                Automates all property management workloads by
                                integrating all rental-related processes in this
                                all-in-one Rentals system.
                            </p>
                            <p className="text-dark-50 font-size-lg mb-7">
                                Partner with us today & do rental management
                                better as we handle your rental units
                                administration.
                            </p>
                            <div className="d-flex">
                                <Link to="/auth/signup">
                                    <button className="btn btn-primary font-weight-bold mr-3">
                                        Get Started
                                    </button>
                                </Link>
                                <Link to="/auth/signup">
                                    <button className="btn btn-outline-primary font-weight-bold">
                                        Learn More
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img
                                src={`${toAbsoluteUrl(
                                    '/media/bg/bg-rental.jpg',
                                )}`}
                                alt="rentals"
                                style={{ height: '400px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* About section */}
            <div className="container-fluid bg-white d-flex flex-column flex-row-fluid align-items-center justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img
                                src={`${toAbsoluteUrl(
                                    '/media/bg/bg-rental.jpg',
                                )}`}
                                alt="rentals"
                                style={{ height: '400px' }}
                            />
                        </div>
                        <div className="col-md-6">
                            <h1 className="text-dark font-weight-bold mb-7">
                                What we offer?
                            </h1>
                            <p className="text-dark-50 font-size-lg mb-7">
                                The provided system is a solution to all rental
                                management related workloads and most
                                importantly it is an automated and real-time
                                system which handles all rental & tenancy
                                management tasks such as income, expenses,
                                tenant invoices and automated payments tracking,
                                etc.
                                <p></p>
                                This system bridges the gap between property
                                owners and tenants by enabling the former to
                                acquire and manage tenants while enabling the
                                property seekers to search and book properties
                                according to their preferences.
                            </p>
                            <p className="text-dark-50 font-size-lg mb-7">
                                Real-time data records of tenant invoices,
                                arrears and settlements can all be tracked from
                                an all-in-one system which essentially automates
                                all property management workloads by integrating
                                all rental-related processes in a single system.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-white d-flex flex-column flex-row-fluid align-items-center justify-content-center p-12">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-dark font-weight-bold mb-7">
                                Who's this platform for?
                            </h1>
                            <p className="text-dark-50 font-size-lg mb-7">
                                This rental platform is intended to be used by
                                property owners, property management agencies as
                                well as property seekers and tenants.
                            </p>
                        </div>
                        <div className="col-md-8">
                            <h1 className="text-dark font-weight-bold mb-7">
                                Platform Features & Benefits?
                            </h1>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        An all-in-one rental management system
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lease management & automated management
                                        of rental-related tasks
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>
                                        A Property units & tenants onboarding
                                        portal
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        As a landlord you can onboard your
                                        tenants and property units. The
                                        onboarded tenant can hence use the said
                                        platform to pay due rent & utility bills
                                        (through integrated payment methods such
                                        as M-Pesa, PesaLink, Bank, etc) via a
                                        portable mobile application interface of
                                        the Rentals platform. A property renter
                                        can also communicate or get on time
                                        notifications/updates from the property
                                        owner. Property owners & property
                                        management agencies can then track back
                                        such records through this Rentals system
                                        which essentially is a convenient medium
                                        between property owners/agencies and
                                        tenants, through which, the two sides
                                        can track concurrent tenancy-related
                                        records/transactions.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        A Tenants management platform
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Tenants onboarding & management
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>
                                        Automatic rent collection through
                                        integrated payment gateways
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Collect your tenants rent through
                                        integrated payment gateways of your
                                        choice: M-Pesa, E-Wallet, Bank Transfer,
                                        etc.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        An integrated reporting system of all
                                        rental-related processes
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Through various dashboards & graphs,
                                        periodic (monthly, quarterly and yearly)
                                        reports.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>
                                        An all-in-one tenancy-related functions
                                        application
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        As a landlord you can onboard your
                                        tenants and through a portable mobile
                                        application, enable them to track their
                                        tenancy-related items such as rent
                                        payments & reminders (rent payment
                                        through the portal rental application &
                                        receipts management), bills, utility
                                        expenses (e.g. security, water &
                                        electricity), arrears, and invoices,
                                        etc.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>
                                        A property advertising platform for
                                        property owners
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        As a landlord/property owner you can
                                        setup your property profile, specify
                                        property unity availability, property
                                        features, location and price for
                                        potential tenants/property seekers
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="col-md-4">
                            <img
                                src={`${toAbsoluteUrl(
                                    '/media/bg/bg-rental-3.svg',
                                )}`}
                                alt="rentals"
                                style={{ height: '300px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white d-flex flex-column flex-row-fluid align-items-center p-12">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <h1 className="text-dark text-center font-weight-bold mb-7">
                                Why you should trust us
                            </h1>
                            <p className="text-dark-50 text-center font-size-lg mb-7">
                                This platform is a software designed and
                                developed by Chamasoft (chamasoft.com - under
                                Digital Vision EA) an experienced investment
                                groups (chamas) management software service used
                                by paying customers across the globe. The
                                company has over 15+ years of experience
                                developing key fintech products that are used
                                worldwide by our clients.
                                <p></p>
                                We have created partnerships with key banking
                                institutions (backing all investment group
                                banking transactions) such as Equity Bank and
                                various Non-Governmental Organizations (NGOs).
                                We continue to leave our international footprint
                                as we serve more than 100,000 clients
                                internationally. We have implemented strict data
                                privacy policies, aimed at protecting our
                                clients data from access by third parties.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        Join as a landlord
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        <Link to="/auth/login">Sign up</Link>
                                    </Typography>

                                    <Typography variant="body2" component="p">
                                        Onboard your property.
                                        <br />
                                    </Typography>
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        Sign up as an Agency
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        <Link to="/auth/login">Join us</Link>
                                    </Typography>

                                    <Typography variant="body2" component="p">
                                        Onboard your managed properties.
                                        <br />
                                    </Typography>
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        Tenants
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                        <Link to="/auth/login">Onboard</Link>
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        As a landlord onboard your tenants.
                                        <br />
                                    </Typography>
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-white d-flex flex-column flex-row-fluid align-items-center justify-content-center p-12">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-dark text-center font-weight-bold mb-7">
                                System Dashboards & Illustrations
                            </h1>
                            <p className="text-dark-50 text-center font-size-lg mb-7">
                                This is what we provide. Explore rental reports
                                through real-time dashboards and automated
                                reports
                            </p>
                            <ImageList
                                sx={{ width: 500, height: 450 }}
                                cols={3}
                                rowHeight={164}
                            >
                                {itemData.map(item => (
                                    <ImageListItem key={item.img}>
                                        <img
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            {/* <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                    <h1 className="error-title font-size-h4 font-weight-boldest text-info mt-10 mt-md-0 mb-12">
                        Rental Management Overview
                    </h1>
                    <p className="font-weight-boldest display-4">
                        Manage your real estate properties with ease and
                        comfort.
                    </p>
                    <p className="font-size-h3 opacity-80">
                        We're working on it and we'll get it fixed
                        <br />
                        as soon possible.
                    </p>
                    <p>
                        <Link
                            to="/auth/signup"
                            className="font-weight-bold btn btn-light-primary btn-wide px-5"
                        >
                            Get Started!
                        </Link>
                    </p>
                </div> */}
        </div>
    );
}
