import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { resetPassword } from '../_redux/authCrud';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { useParams } from 'react-router';
// Library references: https://catamphetamine.gitlab.io/react-phone-number-input/
import 'react-phone-number-input/style.css';
import MuiPhoneInput from 'material-ui-phone-number';
import { appConfigs } from '../../../../_chamasoft/_helpers';
import queryString from 'query-string';

const initialValues = {
    email: '',
    phone: '',
};

function ForgotPassword(props) {
    document.title = 'Recover password — ' + appConfigs.app_name;
    const getParams = queryString.parse(window.location.search);
    const fiLayout = getParams?.for === 'fi' || false;
    let { type } = useParams();
    const [_phone, setPhone] = useState(initialValues.phone);
    type === 'phone' ? (type = 'phone') : (type = 'email');
    const { intl } = props;
    var alert;
    !alert
        ? (alert = {
              msg: intl.formatMessage(
                  { id: 'AUTH.FORGOT.PASSWORD.INTRO' },
                  { type: type },
              ),
              type: 'info',
          })
        : (alert = props.history.location.state?.alert);
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordSchema = Yup.object().shape(
        type === 'email'
            ? {
                  email: Yup.string()
                      .email(
                          intl.formatMessage(
                              { id: 'AUTH.VALIDATION.INVALID' },
                              { name: 'Email' },
                          ),
                      )
                      .min(
                          5,
                          intl.formatMessage(
                              { id: 'AUTH.VALIDATION.MIN_LENGTH' },
                              { name: 'Email', min: '5' },
                          ),
                      )
                      .max(
                          250,
                          intl.formatMessage(
                              { id: 'AUTH.VALIDATION.MAX_LENGTH' },
                              { name: 'Email', max: '250' },
                          ),
                      )
                      .required(
                          intl.formatMessage(
                              { id: 'AUTH.VALIDATION.REQUIRED' },
                              { name: 'Email' },
                          ),
                      ),
              }
            : {
                  phone: Yup.string().required(
                      intl.formatMessage(
                          { id: 'AUTH.VALIDATION.REQUIRED' },
                          { name: 'Phone' },
                      ),
                  ),
              },
    );

    const inputHasError = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return true;
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            var navigateTo = '';
            var navigateAlert = {};
            var identity = type === 'phone' ? _phone : values.email;

            resetPassword(type, identity)
                .then(resp => {
                    // console.log(resp.data);
                    if (resp.data.result_code === 1) {
                        navigateAlert = {
                            msg: intl.formatMessage(
                                {
                                    id:
                                        'AUTH.RESET.PASSWORD.PASSWORD_RESET_CODE_SENT',
                                },
                                { identity: identity },
                            ),
                            type: 'success',
                        };
                        navigateTo = fiLayout
                            ? '/auth/confirm-code/' +
                              type +
                              '?for=fi&type=' +
                              type +
                              '&id=' +
                              identity
                            : '/auth/confirm-code/' +
                              type +
                              '?id=' +
                              identity +
                              '&type=' +
                              type;
                    } else {
                        navigateAlert = {
                            msg: intl.formatMessage({
                                id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                            }),
                            type: 'danger',
                        };
                        navigateTo = fiLayout
                            ? '/auth/login?for=fi'
                            : '/auth/login';
                    }
                    // setIsRequested(true);
                })
                .catch(() => {
                    setIsRequested(false);
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({
                            id: 'AUTH.RESET.PASSWORD.PASSWORD_RESET_ERROR',
                        }),
                    );
                })
                .finally(() => {
                    if (navigateTo !== '')
                        props.history.push(navigateTo, {
                            alert: navigateAlert,
                            identity: identity,
                        });
                });
        },
    });

    const renderInput = () => {
        return type === 'email' ? (
            <div className="form-group fv-plugins-icon-container mb-5">
                <TextField
                    error={inputHasError('email')}
                    label="Your email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    {...formik.getFieldProps('email')}
                    margin="normal"
                />
                {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            {formik.errors.email}
                        </div>
                    </div>
                ) : null}
            </div>
        ) : (
            <div className="form-group fv-plugins-icon-container mt-7 mb-5">
                <input
                    type="hidden"
                    name="phone"
                    value={_phone}
                    {...formik.getFieldProps('phone')}
                />
                <MuiPhoneInput
                    error={inputHasError('phone')}
                    label="Phone"
                    name="_phone"
                    value={_phone}
                    countryCodeEditable={false}
                    inputClass={'cs-phone-inp'}
                    defaultCountry="ke"
                    autoComplete="off"
                    onChange={setPhone}
                    onKeyUp={e => formik.setFieldValue('phone', e.target.value)}
                />
                {formik.touched.phone && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            {formik.errors.phone}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <>
            {isRequested && <Redirect to="/auth" />}
            {!isRequested && (
                <div
                    className="login-form login-forgot"
                    style={{ display: 'block' }}
                >
                    {/* begin::Head */}
                    <div className="text-left mb-5 mb-lg-20-">
                        <h3 className="font-weight-bolder mb-0">
                            <FormattedMessage id="AUTH.FORGOT.PASSWORD" />
                        </h3>
                        <p className="text-dark-50 font-weight-normal">
                            <FormattedMessage
                                id="AUTH.FORGOT.PASSWORD.DESCRIPTION"
                                values={{ type: type }}
                            />
                        </p>
                    </div>
                    {/* end::Head */}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {formik.status.msg}
                                </div>
                            </div>
                        ) : alert ? (
                            <div
                                className={`mb-2 alert alert-custom alert-light-${alert.type} alert-dismissible`}
                            >
                                <div className="alert-text font-weight-bold">
                                    {alert.msg}
                                </div>
                            </div>
                        ) : null}

                        {renderInput()}
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            {/* <Link
                                to={
                                    fiLayout
                                        ? '/auth/confirm-code?for=fi'
                                        : '/auth/confirm-code'
                                }
                                className="text-dark-50 font-weight-normal text-hover-primary"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage id="AUTH.FORGOT.CONFIRMATION.ACTION" />
                            </Link> */}
                            <Link
                                to={
                                    type === 'phone'
                                        ? fiLayout
                                            ? '/auth/forgot-password/email?for=fi'
                                            : '/auth/forgot-password/email'
                                        : fiLayout
                                        ? '/auth/forgot-password/phone?for=fi'
                                        : '/auth/forgot-password/phone'
                                }
                                className="text-dark-50 font-weight-boldest text-hover-primary ml-2"
                                id="kt_login_forgot"
                            >
                                <FormattedMessage
                                    id="AUTH.FORGOT.TYPE.ACTION"
                                    values={{
                                        type:
                                            type === 'phone'
                                                ? 'email'
                                                : 'phone',
                                    }}
                                />
                            </Link>
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary btn-pill btn-sm font-weight-bold px-15 py-4 mr-10"
                                disabled={formik.isSubmitting}
                            >
                                <FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" />
                            </button>
                            <Link
                                to={
                                    fiLayout
                                        ? '/auth/login?for=fi'
                                        : '/auth/login'
                                }
                                className="btn btn-outline-secondary btn-pill btn-sm font-weight-bold px-15 py-4"
                            >
                                <FormattedMessage id="AUTH.GENERAL.LOGIN" />
                            </Link>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
