import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Home } from './Home';

export default function LandingPage() {
    return (
        <Switch>
            <Redirect from="/overview" exact={true} to="/overview/rentals" />
            <Route path="/overview/rentals" component={Home} />
        </Switch>
    );
}
