import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_chamasoft/layout';
import { AuthRedirect } from './pages/AuthRedirect';

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {<Redirect exact from="/" to="/redirect" />}
                <ContentRoute path="/redirect" component={AuthRedirect} />
                {/* <Redirect to="error/err"/> */}
                <Redirect to="/redirect" />
            </Switch>
        </Suspense>
    );
}
