import React from 'react';
import { Link } from 'react-router-dom';
import './assets/plugins/global/plugins.bundle.css';
import './assets/css/style.bundle.css';
import { toAbsoluteUrl } from '../../../_chamasoft/_helpers';

// Create the function
export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.type = 'text/babel';
    script.async = true;
    document.body.appendChild(script);
}

export function Home() {
    // var hostUrl = "assets/";
    var defaultThemeMode = 'light';
    var themeMode;
    if (document.documentElement) {
        if (document.documentElement.hasAttribute('data-theme-mode')) {
            themeMode = document.documentElement.getAttribute(
                'data-theme-mode',
            );
        } else {
            if (localStorage.getItem('data-theme') !== null) {
                themeMode = localStorage.getItem('data-theme');
            } else {
                themeMode = defaultThemeMode;
            }
        }
        if (themeMode === 'system') {
            themeMode = window.matchMedia('(prefers-color-scheme: dark)')
                .matches
                ? 'dark'
                : 'light';
        }
        document.documentElement.setAttribute('data-theme', themeMode);
    }

    return (
        <>
            <div
                id="kt_body"
                data-bs-spy="scroll"
                data-bs-target="#kt_landing_menu"
                className="bg-white position-relative app-blank"
            >
                {/*begin::Theme mode setup on page load*/}
                {/*end::Theme mode setup on page load*/}
                {/*begin::Root*/}
                <div className="d-flex flex-column flex-root" id="kt_app_root">
                    {/*begin::Header Section*/}
                    <div className="mb-0" id="home">
                        {/*begin::Wrapper*/}
                        <div
                            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                            style={{
                                backgroundImage: `url(${require('./assets/media/svg/illustrations/landing.svg')})`,
                            }}
                        >
                            {/*begin::Header*/}
                            <div
                                className="landing-header"
                                data-kt-sticky="true"
                                data-kt-sticky-name="landing-header"
                                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
                            >
                                {/*begin::Container*/}
                                <div className="container">
                                    {/*begin::Wrapper*/}
                                    <div className="d-flex align-items-center justify-content-between">
                                        {/*begin::Logo*/}
                                        <div className="d-flex align-items-center flex-equal">
                                            {/*begin::Mobile menu toggle*/}
                                            <button
                                                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                                id="kt_landing_menu_toggle"
                                            >
                                                {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                                                <span className="svg-icon svg-icon-2hx">
                                                    <svg
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            opacity="0.3"
                                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </button>
                                            {/*end::Mobile menu toggle*/}
                                            {/*begin::Logo image*/}
                                            <a href="/">
                                                <img
                                                    // alt="Logo"
                                                    // src={require('./assets/media/logos/landing.svg')}
                                                    src={`${toAbsoluteUrl(
                                                        '/media/logos/logo.png',
                                                    )}`}
                                                    alt="realtors"
                                                    className="logo-default h-25px h-lg-30px"
                                                />
                                                <img
                                                    // alt="Logo"
                                                    // src={require('./assets/media/logos/landing-dark.svg')}
                                                    src={`${toAbsoluteUrl(
                                                        '/media/logos/logo.png',
                                                    )}`}
                                                    alt="realtors"
                                                    className="logo-sticky h-20px h-lg-25px"
                                                />
                                            </a>
                                            {/*end::Logo image*/}
                                        </div>
                                        {/*end::Logo*/}
                                        {/*begin::Menu wrapper*/}
                                        <div
                                            className="d-lg-block"
                                            id="kt_header_nav_wrapper"
                                        >
                                            <div
                                                className="d-lg-block p-5 p-lg-0"
                                                data-kt-drawer="true"
                                                data-kt-drawer-name="landing-menu"
                                                data-kt-drawer-activate="{default: true, lg: false}"
                                                data-kt-drawer-overlay="true"
                                                data-kt-drawer-width="200px"
                                                data-kt-drawer-direction="start"
                                                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                                                data-kt-swapper="true"
                                                data-kt-swapper-mode="prepend"
                                                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                                            >
                                                {/*begin::Menu*/}
                                                <div
                                                    className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                                    id="kt_landing_menu"
                                                >
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item">
                                                        {/*begin::Menu link*/}
                                                        <a
                                                            className="menu-link nav-link active py-3 px-4 px-xxl-6"
                                                            href="/#"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Home
                                                        </a>
                                                        {/*end::Menu link*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item">
                                                        {/*begin::Menu link*/}
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#system-features"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Features
                                                        </a>
                                                        {/*end::Menu link*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item">
                                                        {/*begin::Menu link*/}
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#product"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Product
                                                        </a>
                                                        {/*end::Menu link*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item">
                                                        {/*begin::Menu link*/}
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#partner-with-us"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Partner With Us
                                                        </a>
                                                        {/*end::Menu link*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item">
                                                        {/*begin::Menu link*/}
                                                        <a
                                                            className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                            href="#portfolio"
                                                            data-kt-scroll-toggle="true"
                                                            data-kt-drawer-dismiss="true"
                                                        >
                                                            Portfolio
                                                        </a>
                                                        {/*end::Menu link*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                </div>
                                                {/*end::Menu*/}
                                            </div>
                                        </div>
                                        {/*end::Menu wrapper*/}
                                        {/*begin::Toolbar*/}
                                        <div className="flex-equal text-end ms-1">
                                            <Link to="/auth/login">
                                                <button className="btn btn-success">
                                                    Sign-in
                                                </button>
                                            </Link>
                                        </div>
                                        {/*end::Toolbar*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                </div>
                                {/*end::Container*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Landing hero*/}
                            <div
                                className="d-flex flex-column flex-center justify-content-around w-100 min-h-350px min-h-lg-500px px-9 mx-auto"
                                style={{ maxWidth: '80%' }}
                            >
                                <div className="row">
                                    {/*begin::Heading*/}
                                    <div className="col-md-6 text-center text-md-left">
                                        <div className="mb-5 mb-lg-10 py-10 py-lg-20">
                                            {/*begin::Title*/}
                                            <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">
                                                Partner with us today & Automate
                                                <br />
                                                your &nbsp;
                                                <span
                                                    style={{
                                                        background:
                                                            'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)',
                                                        WebkitBackgroundClip:
                                                            'text',
                                                        WebkitTextFillColor:
                                                            'transparent',
                                                    }}
                                                >
                                                    <span id="kt_landing_hero_text">
                                                        Rental Management
                                                    </span>
                                                </span>
                                            </h1>
                                            {/*end::Title*/}
                                            {/*begin::Action*/}
                                            <Link to="/auth/signup">
                                                <button className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">
                                                    Get Started
                                                </button>
                                            </Link>
                                            &nbsp;&nbsp;
                                            <a href="#system-features">
                                                <button className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">
                                                    Learn More
                                                </button>
                                            </a>
                                            {/*end::Action*/}
                                        </div>
                                        {/*end::Heading*/}
                                    </div>
                                    {/* end::col */}
                                    {/* --------------------------------------------------------------------------- */}
                                    <div className="col-md-6 d-flex justify-content-center">
                                        <div className="mb-5 mb-lg-10 py-10 py-lg-20 d-flex justify-content-center">
                                            <img
                                                src={`${toAbsoluteUrl(
                                                    '/media/bg/device_dashboard.png',
                                                )}`}
                                                alt="rentals"
                                                style={{ maxWidth: '137%' }}
                                            />
                                        </div>
                                    </div>
                                    {/* --------------------------------------------------------------------------- */}
                                </div>
                                {/* end::row */}
                            </div>
                            {/*end::Landing hero*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Curve bottom*/}
                        <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                            <svg
                                viewBox="15 12 1470 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        {/*end::Curve bottom*/}
                    </div>
                    {/*end::Header Section*/}
                    {/*begin::How It Works Section*/}
                    <div className="mb-n10 mb-lg-n20 z-index-2">
                        {/*begin::Container*/}
                        <div className="container">
                            {/*begin::Heading*/}
                            <div className="text-center mb-17">
                                {/*begin::Title*/}
                                <h3
                                    className="fs-2hx text-dark mb-5"
                                    id="system-features"
                                    data-kt-scroll-offset="{default: 100, lg: 150}"
                                >
                                    An All-In-One System
                                </h3>
                                {/*end::Title*/}
                                {/*begin::Text*/}
                                <div className="fs-5 text-muted fw-bold">
                                    Save thousands to millions of bucks by using
                                    single tool
                                    <br />
                                    for different amazing and great useful admin
                                </div>
                                {/*end::Text*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Row*/}
                            <div className="row w-100 gy-10 mb-md-20">
                                {/*begin::Col*/}
                                <div className="col-md-4 px-5">
                                    {/*begin::Story*/}
                                    <div className="text-center mb-10 mb-md-0">
                                        {/*begin::Illustration*/}
                                        <img
                                            src={require('./assets/media/illustrations/sketchy-1/2.png')}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        {/*end::Illustration*/}
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-center mb-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                1
                                            </span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">
                                                Property Management
                                            </div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Heading*/}
                                        {/*begin::Description*/}
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Manage your property units &
                                            tenants.
                                            <br />
                                            Automates all property management
                                            workloads by integrating
                                            <br />
                                            all rental-related processes in this
                                            all-in-one Rentals system.
                                        </div>
                                        {/*end::Description*/}
                                    </div>
                                    {/*end::Story*/}
                                </div>
                                {/*end::Col*/}
                                {/*begin::Col*/}
                                <div className="col-md-4 px-5">
                                    {/*begin::Story*/}
                                    <div className="text-center mb-10 mb-md-0">
                                        {/*begin::Illustration*/}
                                        <img
                                            src={require('./assets/media/illustrations/sketchy-1/9.png')}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        {/*end::Illustration*/}
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-center mb-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                2
                                            </span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">
                                                An Rental Reporting System
                                            </div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Heading*/}
                                        {/*begin::Description*/}
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Monitor your property
                                            <br />
                                            through various dashboards & graphs,
                                            periodic
                                            <br />
                                            &#40;monthly, quarterly and
                                            yearly&#41; reports.
                                        </div>
                                        {/*end::Description*/}
                                    </div>
                                    {/*end::Story*/}
                                </div>
                                {/*end::Col*/}
                                {/*begin::Col*/}
                                <div className="col-md-4 px-5">
                                    {/*begin::Story*/}
                                    <div className="text-center mb-10 mb-md-0">
                                        {/*begin::Illustration*/}
                                        <img
                                            src={require('./assets/media/illustrations/sketchy-1/12.png')}
                                            className="mh-125px mb-9"
                                            alt=""
                                        />
                                        {/*end::Illustration*/}
                                        {/*begin::Heading*/}
                                        <div className="d-flex flex-center mb-5">
                                            {/*begin::Badge*/}
                                            <span className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">
                                                3
                                            </span>
                                            {/*end::Badge*/}
                                            {/*begin::Title*/}
                                            <div className="fs-5 fs-lg-3 fw-bold text-dark">
                                                Automated Rent Collection
                                            </div>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Heading*/}
                                        {/*begin::Description*/}
                                        <div className="fw-semibold fs-6 fs-lg-4 text-muted">
                                            Collect your tenants rent through
                                            <br />
                                            integrated payment gateways of your
                                            choice
                                            <br />: M-Pesa, E-Wallet, Bank
                                            Transfer, etc.
                                        </div>
                                        {/*end::Description*/}
                                    </div>
                                    {/*end::Story*/}
                                </div>
                                {/*end::Col*/}
                            </div>
                            {/*end::Row*/}
                        </div>
                        {/*end::Container*/}
                    </div>
                    {/*end::How It Works Section*/}
                    <br />
                    <br />
                    <br />
                    <br />
                    {/*begin::Statistics Section*/}
                    <div className="mt-sm-n10">
                        {/*begin::Wrapper*/}
                        <div className="pb-15 pt-18 landing-dark-bg">
                            {/*begin::Container*/}
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        {/* ------------------------------------------------------------------------------------ */}
                                        <br />
                                        <br />
                                        <img
                                            src={`${toAbsoluteUrl(
                                                '/media/bg/laptop.png',
                                            )}`}
                                            alt="rentals"
                                            style={{ maxWidth: '80%' }}
                                        />
                                        {/* ------------------------------------------------------------------------------------ */}
                                    </div>
                                    <div className="col-md-5">
                                        {/*begin::Heading*/}
                                        <div
                                            className="mt-15 mb-18"
                                            id="product"
                                            data-kt-scroll-offset="{default: 100, lg: 150}"
                                        >
                                            {/*begin::Title*/}
                                            <h3 className="fs-2hx text-white fw-bold mb-5">
                                                The Product We Offer
                                            </h3>
                                            {/*end::Title*/}
                                        </div>
                                        {/*end::Heading*/}

                                        {/*begin::Testimonial*/}
                                        <div className="fs-2 fw-semibold text-muted text-justify mb-3">
                                            A rental management solution which
                                            enables property owners & property
                                            management agencies to manage their
                                            property units & tenants.
                                            <br />
                                            Automates all property management
                                            workloads by integrating all
                                            rental-related processes in this
                                            all-in-one Rentals system.
                                        </div>
                                        {/*end::Testimonial*/}
                                        {/*begin::Author*/}
                                        <br />
                                        <div className="fs-2 fw-semibold text-muted">
                                            <a
                                                href="../../demo1/dist/account/security.html"
                                                className="link-primary fs-4 fw-bold"
                                            >
                                                Realtors -
                                            </a>
                                            <span className="fs-4 fw-bold text-gray-600">
                                                &nbsp; A Rentals Management
                                                Software
                                            </span>
                                        </div>
                                        {/*end::Author*/}
                                    </div>
                                    {/* end::col */}
                                </div>
                                {/* end::row */}
                            </div>
                            {/*end::Container*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Statistics Section*/}

                    {/*begin::Team Section*/}
                    {/* --------------------------------------- TEAM SECTION ---------------------------- */}
                    {/* -------------------------------------------------------------------------------- */}
                    {/*end::Team Section*/}
                    <br />
                    <br />
                    <br />

                    {/*begin::Projects Section*/}
                    <div className="mb-lg-n15 position-relative z-index-2">
                        {/*begin::Container*/}
                        <div className="container">
                            {/*begin::Card*/}
                            <div
                                className="card"
                                style={{
                                    filter:
                                        'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))',
                                }}
                            >
                                {/*begin::Card body*/}
                                <div className="card-body p-lg-20">
                                    {/*begin::Heading*/}
                                    <div className="text-center mb-5 mb-lg-10">
                                        {/*begin::Title*/}
                                        <h3
                                            className="fs-2hx text-dark mb-5"
                                            id="partner-with-us"
                                            data-kt-scroll-offset="{default: 100, lg: 250}"
                                        >
                                            Partner With Us Today!
                                        </h3>
                                        {/*end::Title*/}
                                    </div>
                                    {/*end::Heading*/}
                                    {/*begin::Tabs wrapper*/}
                                    <div className="d-flex flex-center mb-5 mb-lg-15">
                                        {/*begin::Tabs*/}
                                        <div className="fs-5 text-muted fw-bold">
                                            We provide you with an all-in-one
                                            system to manage your rental through
                                            real-time dashboards and automated
                                            reports
                                        </div>
                                        {/*end::Tabs*/}
                                    </div>
                                    {/*end::Tabs wrapper*/}
                                    {/*begin::Tabs content*/}
                                    <div className="tab-content">
                                        {/*begin::Tab pane*/}
                                        <div
                                            className="tab-pane fade show active"
                                            id="kt_landing_projects_latest"
                                        >
                                            {/*begin::Row*/}
                                            <div className="row g-10">
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay h-lg-100"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/preview/demos/demo1/dark-rtl.png')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Row*/}
                                                    <div className="row g-10 mb-10">
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap2.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap3.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Row*/}
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/rentals.jpg')}`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                            </div>
                                            {/*end::Row*/}
                                        </div>
                                        {/*end::Tab pane*/}
                                        {/*begin::Tab pane*/}
                                        <div
                                            className="tab-pane fade"
                                            id="kt_landing_projects_web_design"
                                        >
                                            {/*begin::Row*/}
                                            <div className="row g-10">
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay h-lg-100"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/preview/demos/demo1/dark-rtl.png')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Row*/}
                                                    <div className="row g-10 mb-10">
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap2.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap3.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Row*/}
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/rentals.jpg')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                            </div>
                                            {/*end::Row*/}
                                        </div>
                                        {/*end::Tab pane*/}
                                        {/*begin::Tab pane*/}
                                        <div
                                            className="tab-pane fade"
                                            id="kt_landing_projects_mobile_apps"
                                        >
                                            {/*begin::Row*/}
                                            <div className="row g-10">
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Row*/}
                                                    <div className="row g-10 mb-10">
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/preview/demos/demo1/dark-rtl.png')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap2.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Row*/}
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/imgcap3.jpg')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay h-lg-100"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/rentals.jpg')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                            </div>
                                            {/*end::Row*/}
                                        </div>
                                        {/*end::Tab pane*/}
                                        {/*begin::Tab pane*/}
                                        <div
                                            className="tab-pane fade"
                                            id="kt_landing_projects_development"
                                        >
                                            {/*begin::Row*/}
                                            <div className="row g-10">
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay h-lg-100"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/preview/demos/demo1/dark-rtl.png')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                                {/*begin::Col*/}
                                                <div className="col-lg-6">
                                                    {/*begin::Row*/}
                                                    <div className="row g-10 mb-10">
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap2.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-6">
                                                            {/*begin::Item*/}
                                                            <a
                                                                className="d-block card-rounded overlay"
                                                                data-fslightbox="lightbox-projects"
                                                                href="/#"
                                                            >
                                                                {/*begin::Image*/}
                                                                <div
                                                                    className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                                    style={{
                                                                        backgroundImage: `url(${require('./assets/media/imgcap3.jpg')})`,
                                                                    }}
                                                                />
                                                                {/*end::Image*/}
                                                                {/*begin::Action*/}
                                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                    <i className="bi bi-eye-fill fs-3x text-white" />
                                                                </div>
                                                                {/*end::Action*/}
                                                            </a>
                                                            {/*end::Item*/}
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Row*/}
                                                    {/*begin::Item*/}
                                                    <a
                                                        className="d-block card-rounded overlay"
                                                        data-fslightbox="lightbox-projects"
                                                        href="/#"
                                                    >
                                                        {/*begin::Image*/}
                                                        <div
                                                            className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"
                                                            style={{
                                                                backgroundImage: `url(${require('./assets/media/rentals.jpg')})`,
                                                            }}
                                                        />
                                                        {/*end::Image*/}
                                                        {/*begin::Action*/}
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="bi bi-eye-fill fs-3x text-white" />
                                                        </div>
                                                        {/*end::Action*/}
                                                    </a>
                                                    {/*end::Item*/}
                                                </div>
                                                {/*end::Col*/}
                                            </div>
                                            {/*end::Row*/}
                                        </div>
                                        {/*end::Tab pane*/}
                                    </div>
                                    {/*end::Tabs content*/}
                                </div>
                                {/*end::Card body*/}
                            </div>
                            {/*end::Card*/}
                        </div>
                        {/*end::Container*/}
                    </div>
                    {/*end::Projects Section*/}
                    {/*begin::Pricing Section*/}
                    <div className="mt-sm-n20">
                        {/*begin::Curve top*/}
                        <div className="landing-curve landing-dark-color">
                            <svg
                                viewBox="15 -1 1470 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        {/*end::Curve top*/}
                        {/*begin::Wrapper*/}
                        <div className="py-20 landing-dark-bg">
                            {/*begin::Container*/}
                            <div className="container">
                                {/*begin::Plans*/}
                                <div className="d-flex flex-column container pt-lg-20">
                                    {/*begin::Heading*/}
                                    <div className="mb-13 text-center">
                                        <h1
                                            className="fs-2hx fw-bold text-white mb-5"
                                            id="pricing"
                                            data-kt-scroll-offset="{default: 100, lg: 150}"
                                        >
                                            Why You Should Choose Us?
                                        </h1>
                                        <div className="text-gray-600 fw-semibold fs-5">
                                            <br />
                                            The provided system is a solution to
                                            all rental management related
                                            workloads and most importantly it is
                                            an automated and real-time system
                                            which handles all rental & tenancy
                                            management tasks such as income,
                                            expenses, tenant invoices and
                                            automated payments tracking, etc.
                                            <br />
                                            This system bridges the gap between
                                            property owners and tenants by
                                            enabling the former to acquire and
                                            manage tenants while enabling the
                                            property seekers to search and book
                                            properties according to their
                                            preferences.
                                        </div>
                                    </div>
                                    {/*end::Heading*/}
                                    {/*begin::Pricing*/}
                                    <div
                                        className="text-center"
                                        id="kt_pricing"
                                    >
                                        {/*begin::Nav group*/}
                                        <div
                                            className="nav-group landing-dark-bg d-inline-flex mb-15"
                                            data-kt-buttons="true"
                                            style={{
                                                border: '1px dashed #2B4666',
                                            }}
                                        >
                                            <a
                                                href="/"
                                                className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active"
                                                data-kt-plan="month"
                                            >
                                                Get started
                                            </a>
                                            <a
                                                href="/overview/rentals"
                                                className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3"
                                                data-kt-plan="annual"
                                            >
                                                Learn more
                                            </a>
                                        </div>
                                        {/*end::Nav group*/}
                                        {/* ------------------------- PLACE FOR PRICING ROW ----------------------------- */}
                                        {/* -------------------------------------------------------------------------- */}
                                    </div>
                                    {/*end::Pricing*/}
                                </div>
                                {/*end::Plans*/}
                            </div>
                            {/*end::Container*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Curve bottom*/}
                        <div className="landing-curve landing-dark-color">
                            <svg
                                viewBox="15 12 1470 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        {/*end::Curve bottom*/}
                    </div>
                    {/*end::Pricing Section*/}
                    {/*begin::Testimonials Section*/}
                    <div className="mt-20 mb-n20 position-relative z-index-2">
                        {/*begin::Container*/}
                        <div className="container">
                            {/*begin::Heading*/}
                            <div className="text-center mb-17">
                                {/*begin::Title*/}
                                <h3
                                    className="fs-2hx text-dark mb-5"
                                    id="portfolio"
                                    data-kt-scroll-offset="{default: 125, lg: 150}"
                                >
                                    Why You Should Trust Us
                                </h3>
                                {/*end::Title*/}
                                {/*begin::Description*/}
                                <div className="fs-5 text-muted fw-bold">
                                    Manage your properties and tenants
                                    efficiently
                                    <br />
                                    Track your expenses and profits & automate
                                    your rent collection
                                </div>
                                {/*end::Description*/}
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Row*/}
                            <div className="row g-lg-10 mb-10 mb-lg-20">
                                {/*begin::Col*/}
                                <div className="col-lg-4">
                                    {/*begin::Testimonial*/}
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        {/*begin::Wrapper*/}
                                        <div className="mb-7">
                                            {/*begin::Rating*/}
                                            <div className="rating mb-6">
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                            </div>
                                            {/*end::Rating*/}
                                            {/*begin::Title*/}
                                            <div className="fs-2 fw-bold text-dark mb-3">
                                                This platform is a software
                                                <br />
                                                designed and
                                            </div>
                                            {/*end::Title*/}
                                            {/*begin::Feedback*/}
                                            <div className="text-gray-500 fw-semibold fs-4">
                                                developed by Chamasoft
                                                &#40;chamasoft.com - under
                                                Digital Vision EA&#41; an
                                                experienced investment groups
                                                &#40;chamas&#41; management
                                                software service used by paying
                                                customers across the globe.
                                            </div>
                                            {/*end::Feedback*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Testimonial*/}
                                </div>
                                {/*end::Col*/}
                                {/*begin::Col*/}
                                <div className="col-lg-4">
                                    {/*begin::Testimonial*/}
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        {/*begin::Wrapper*/}
                                        <div className="mb-7">
                                            {/*begin::Rating*/}
                                            <div className="rating mb-6">
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                            </div>
                                            {/*end::Rating*/}
                                            {/*begin::Title*/}
                                            <div className="fs-2 fw-bold text-dark mb-3">
                                                The company has over 15+ years
                                                of experience developing key
                                            </div>
                                            {/*end::Title*/}
                                            {/*begin::Feedback*/}
                                            <div className="text-gray-500 fw-semibold fs-4">
                                                fintech products that are used
                                                worldwide by our clients. We
                                                have created partnerships with
                                                key banking institutions <br />{' '}
                                                &#40;backing all investment
                                                group banking transactions&#41;
                                                such as Equity Bank and various
                                                Non-Governmental Organizations
                                                &#40;NGOs&#41;.
                                            </div>
                                            {/*end::Feedback*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Testimonial*/}
                                </div>
                                {/*end::Col*/}
                                {/*begin::Col*/}
                                <div className="col-lg-4">
                                    {/*begin::Testimonial*/}
                                    <div className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                                        {/*begin::Wrapper*/}
                                        <div className="mb-7">
                                            {/*begin::Rating*/}
                                            <div className="rating mb-6">
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                                <div className="rating-label me-2 checked">
                                                    <i className="bi bi-star-fill fs-5" />
                                                </div>
                                            </div>
                                            {/*end::Rating*/}
                                            {/*begin::Title*/}
                                            <div className="fs-2 fw-bold text-dark mb-3">
                                                We continue to leave our
                                                <br />
                                                international footprint
                                            </div>
                                            {/*end::Title*/}
                                            {/*begin::Feedback*/}
                                            <div className="text-gray-500 fw-semibold fs-4">
                                                as we serve more than 100,000
                                                clients internationally. We have
                                                implemented strict data privacy
                                                policies, aimed at protecting
                                                our clients data from access by
                                                third parties.
                                            </div>
                                            {/*end::Feedback*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                    </div>
                                    {/*end::Testimonial*/}
                                </div>
                                {/*end::Col*/}
                            </div>
                            {/*end::Row*/}
                            {/*begin::Highlight*/}
                            <div
                                className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
                                style={{
                                    background:
                                        'linear-gradient(90deg, #20AA3E 0%, #03A588 100%)',
                                }}
                            >
                                {/*begin::Content*/}
                                <div className="my-2 me-5">
                                    {/*begin::Title*/}
                                    <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">
                                        Partner with us today & Automate your
                                        &nbsp;
                                        <span className="fw-normal">
                                            Rental Management
                                        </span>
                                    </div>
                                    {/*end::Title*/}
                                    {/*begin::Description*/}
                                    <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">
                                        Start With Realtors Today, Manage your
                                        tenants!
                                    </div>
                                    {/*end::Description*/}
                                </div>
                                {/*end::Content*/}
                                {/*begin::Link*/}
                                <Link to="/auth/signup">
                                    <button className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">
                                        Sign up Today!
                                    </button>
                                </Link>
                                {/*end::Link*/}
                            </div>
                            {/*end::Highlight*/}
                        </div>
                        {/*end::Container*/}
                    </div>
                    {/*end::Testimonials Section*/}
                    {/*begin::Footer Section*/}
                    <div className="mb-0">
                        {/*begin::Curve top*/}
                        <div className="landing-curve landing-dark-color">
                            <svg
                                viewBox="15 -1 1470 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        {/*end::Curve top*/}
                        {/*begin::Wrapper*/}
                        <div className="landing-dark-bg pt-20">
                            {/*begin::Container*/}
                            <div className="container">
                                {/*begin::Row*/}
                                <div className="row py-10 py-lg-20">
                                    {/*begin::Col*/}
                                    <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                                        {/*begin::Block*/}
                                        <div className="rounded landing-dark-border p-9 mb-10">
                                            {/*begin::Title*/}
                                            <h2 className="text-white">
                                                Would you need a Custom License?
                                            </h2>
                                            {/*end::Title*/}
                                            {/*begin::Text*/}
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Email us to &nbsp;
                                                <a
                                                    href="/"
                                                    className="text-white opacity-50 text-hover-primary"
                                                >
                                                    info@chamasoft.com
                                                </a>
                                            </span>
                                            {/*end::Text*/}
                                        </div>
                                        {/*end::Block*/}
                                        {/*begin::Block*/}
                                        <div className="rounded landing-dark-border p-9">
                                            {/*begin::Title*/}
                                            <h2 className="text-white">
                                                Want To Onboard Your Rental?
                                            </h2>
                                            {/*end::Title*/}
                                            {/*begin::Text*/}
                                            <span className="fw-normal fs-4 text-gray-700">
                                                Get started and quickly manage
                                                your rental.
                                                <a
                                                    href="/"
                                                    className="text-white opacity-50 text-hover-primary"
                                                >
                                                    &nbsp; Click to Get Started
                                                </a>
                                            </span>
                                            {/*end::Text*/}
                                        </div>
                                        {/*end::Block*/}
                                    </div>
                                    {/*end::Col*/}
                                    {/*begin::Col*/}
                                    <div className="col-lg-6 ps-lg-16">
                                        {/*begin::Navs*/}
                                        <div className="d-flex justify-content-center">
                                            {/*begin::Links*/}
                                            <div className="d-flex fw-semibold flex-column me-20">
                                                {/*begin::Subtitle*/}
                                                <h4 className="fw-bold text-gray-400 mb-6">
                                                    More for Realtors
                                                </h4>
                                                {/*end::Subtitle*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="mailto:info@chamasoft.com"
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >
                                                    FAQ
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/portfolio/"
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >
                                                    Portfolio
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/about-digital-vision/digital-vision-web-applications-and-mobile-applications-team/"
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >
                                                    DV Team
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/services/product-development/"
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >
                                                    DV Services
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="mailto:info@chamasoft.com"
                                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6"
                                                >
                                                    Contact Support
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/"
                                                    className="text-white opacity-50 text-hover-primary fs-5"
                                                >
                                                    Blog
                                                </a>
                                                {/*end::Link*/}
                                            </div>
                                            {/*end::Links*/}
                                            {/*begin::Links*/}
                                            <div className="d-flex fw-semibold flex-column ms-lg-20">
                                                {/*begin::Subtitle*/}
                                                <h4 className="fw-bold text-gray-400 mb-6">
                                                    Stay Connected
                                                </h4>
                                                {/*end::Subtitle*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://web.facebook.com/digitalvisionea/?_rdc=1&_rdr"
                                                    className="mb-6"
                                                >
                                                    <img
                                                        src={require('./assets/media/svg/brand-logos/facebook-4.svg')}
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Facebook
                                                    </span>
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://github.com/Digital-Vision-EA"
                                                    className="mb-6"
                                                >
                                                    <img
                                                        src={require('./assets/media/svg/brand-logos/github.svg')}
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Github
                                                    </span>
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://twitter.com/digitalvisionea"
                                                    className="mb-6"
                                                >
                                                    <img
                                                        src={require('./assets/media/svg/brand-logos/twitter.svg')}
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Twitter
                                                    </span>
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/"
                                                    className="mb-6"
                                                >
                                                    <img
                                                        src={require('./assets/media/svg/brand-logos/dribbble-icon-1.svg')}
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Dribbble
                                                    </span>
                                                </a>
                                                {/*end::Link*/}
                                                {/*begin::Link*/}
                                                <a
                                                    href="https://digitalvisionea.com/"
                                                    className="mb-6"
                                                >
                                                    <img
                                                        src={require('./assets/media/svg/brand-logos/instagram-2-1.svg')}
                                                        className="h-20px me-2"
                                                        alt=""
                                                    />
                                                    <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">
                                                        Instagram
                                                    </span>
                                                </a>
                                                {/*end::Link*/}
                                            </div>
                                            {/*end::Links*/}
                                        </div>
                                        {/*end::Navs*/}
                                    </div>
                                    {/*end::Col*/}
                                </div>
                                {/*end::Row*/}
                            </div>
                            {/*end::Container*/}
                            {/*begin::Separator*/}
                            <div className="landing-dark-separator" />
                            {/*end::Separator*/}
                            {/*begin::Container*/}
                            <div className="container">
                                {/*begin::Wrapper*/}
                                <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                                    {/*begin::Copyright*/}
                                    <div className="d-flex align-items-center order-2 order-md-1">
                                        {/*begin::Logo*/}
                                        <a href="../../demo1/dist/landing.html">
                                            <img
                                                // alt="Logo"
                                                // src={require('./assets/media/logos/landing.svg')}
                                                src={`${toAbsoluteUrl(
                                                    '/media/logos/logo.png',
                                                )}`}
                                                alt="realtors"
                                                className="h-15px h-md-20px"
                                            />
                                        </a>
                                        {/*end::Logo image*/}
                                        {/*begin::Logo image*/}
                                        <span
                                            className="mx-5 fs-6 fw-semibold text-gray-600 pt-1"
                                            href="https://keenthemes.com"
                                        >
                                            © 2022 Realtors - Digital Vision EA.
                                        </span>
                                        {/*end::Logo image*/}
                                    </div>
                                    {/*end::Copyright*/}
                                    {/*begin::Menu*/}
                                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                        <li className="menu-item">
                                            <a
                                                href="/overview/rentals"
                                                className="menu-link px-2"
                                            >
                                                About
                                            </a>
                                        </li>
                                        <li className="menu-item mx-5">
                                            <a
                                                href="mailto:info@chamasoft.com"
                                                className="menu-link px-2"
                                            >
                                                Support
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a
                                                href="/"
                                                className="menu-link px-2"
                                            >
                                                Get Started
                                            </a>
                                        </li>
                                    </ul>
                                    {/*end::Menu*/}
                                </div>
                                {/*end::Wrapper*/}
                            </div>
                            {/*end::Container*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Footer Section*/}
                    {/*begin::Scrolltop*/}
                    <div
                        id="kt_scrolltop"
                        className="scrolltop"
                        data-kt-scrolltop="true"
                    >
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                        <span className="svg-icon">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    opacity="0.5"
                                    x={13}
                                    y={6}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(90 13 6)"
                                    fill="currentColor"
                                />
                                <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </div>
                    {/*end::Scrolltop*/}
                </div>
                {/*end::Root*/}
                {/*begin::Engage drawers*/}
                {/*begin::Demos drawer*/}
                <div
                    id="kt_engage_demos"
                    className="bg-body"
                    data-kt-drawer="true"
                    data-kt-drawer-name="explore"
                    data-kt-drawer-activate="true"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
                    data-kt-drawer-direction="end"
                    data-kt-drawer-toggle="#kt_engage_demos_toggle"
                    data-kt-drawer-close="#kt_engage_demos_close"
                >
                    {/*begin::Card*/}
                    <div className="card shadow-none rounded-0 w-100">
                        {/*begin::Header*/}
                        <div
                            className="card-header"
                            id="kt_engage_demos_header"
                        >
                            <h3 className="card-title fw-bold text-gray-700">
                                Demos
                            </h3>
                            <div className="card-toolbar">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6"
                                    id="kt_engage_demos_close"
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-2">
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </button>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body" id="kt_engage_demos_body">
                            {/*begin::Content*/}
                            <div
                                id="kt_explore_scroll"
                                className="scroll-y me-n5 pe-5"
                                data-kt-scroll="true"
                                data-kt-scroll-height="auto"
                                data-kt-scroll-wrappers="#kt_engage_demos_body"
                                data-kt-scroll-dependencies="#kt_engage_demos_header"
                                data-kt-scroll-offset="5px"
                            >
                                {/*begin::Wrapper*/}
                                <div className="mb-0">
                                    {/*begin::Heading*/}
                                    <div className="mb-7">
                                        <div className="d-flex flex-stack">
                                            <h3 className="mb-0">
                                                Metronic Licenses
                                            </h3>
                                            <a href="/" className="fw-semibold">
                                                License FAQs
                                            </a>
                                        </div>
                                    </div>
                                    {/*end::Heading*/}
                                    {/*begin::License*/}
                                    <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center mb-1">
                                                    <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                                                        Regular License
                                                    </div>
                                                    <i
                                                        className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                                                        data-bs-toggle="popover"
                                                        data-bs-custom-class="popover-inverse"
                                                        data-bs-trigger="hover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Use, by you or one client in a single end product which end users are not charged for"
                                                    />
                                                </div>
                                                <div className="fs-7 text-muted">
                                                    For single end product used
                                                    by you or one client
                                                </div>
                                            </div>
                                            <div className="text-nowrap">
                                                <span className="text-muted fs-7 fw-semibold me-n1">
                                                    $
                                                </span>
                                                <span className="text-dark fs-1 fw-bold">
                                                    49
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::License*/}
                                    {/*begin::License*/}
                                    <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center mb-1">
                                                    <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                                                        Extended License
                                                    </div>
                                                    <i
                                                        className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
                                                        data-bs-toggle="popover"
                                                        data-bs-custom-class="popover-inverse"
                                                        data-bs-trigger="hover"
                                                        data-bs-placement="top"
                                                        data-bs-content="Use, by you or one client, in a single end product which end users can be charged for."
                                                    />
                                                </div>
                                                <div className="fs-7 text-muted">
                                                    For single SaaS app with
                                                    paying users
                                                </div>
                                            </div>
                                            <div className="text-nowrap">
                                                <span className="text-muted fs-7 fw-semibold me-n1">
                                                    $
                                                </span>
                                                <span className="text-dark fs-1 fw-bold">
                                                    969
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::License*/}
                                    {/*begin::License*/}
                                    <div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex align-items-center mb-1">
                                                    <div className="fs-6 fw-semibold text-gray-800 fw-semibold mb-0 me-1">
                                                        Custom License
                                                    </div>
                                                </div>
                                                <div className="fs-7 text-muted">
                                                    Reach us for custom license
                                                    offers.
                                                </div>
                                            </div>
                                            <div className="text-nowrap">
                                                <a
                                                    href="/"
                                                    className="btn btn-sm btn-success"
                                                >
                                                    Contact Us
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::License*/}
                                    {/*begin::Purchase*/}
                                    <a
                                        href="https://1.envato.market/EA4JP"
                                        className="btn btn-primary fw-bold mb-15 w-100"
                                    >
                                        Buy Now
                                    </a>
                                    {/*end::Purchase*/}
                                    {/*begin::Demos*/}
                                    <div className="mb-0">
                                        <h3 className="fw-bold text-center mb-6">
                                            30 Metronic Demos
                                        </h3>
                                        {/*begin::Row*/}
                                        <div className="row g-5">
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-success rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Metronic Original
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            SaaS App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            POS System
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            New Trend
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Analytics App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Project Grid
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Finance Planner
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Jobs Site
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Databox Dashboard
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            CRM Software
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            User Guiding App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Sales Tracking App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Member Dashboard
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Project Workspace
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Project Workplace
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Helpdesk App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Planable App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            CRM Dashboard
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Media Publisher
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            eCommerce App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Reports Panel
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Sales Manager
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Classic Dashboard
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Podcast App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Goal Tracking
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Monochrome App
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Data Analyzer
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Events Scheduler
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Crypto Planner
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                            {/*begin::Col*/}
                                            <div className="col-6">
                                                {/*begin::Demo*/}
                                                <div className="overlay overflow-hidden position-relative border border-4 border-gray-200 rounded">
                                                    <div className="overlay-wrapper">
                                                        <img
                                                            src={require('./assets/media/preview/demos/demo1/light-ltr.png')}
                                                            alt="demo"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="overlay-layer bg-dark bg-opacity-10">
                                                        <a
                                                            href="/"
                                                            className="btn btn-sm btn-success shadow"
                                                        >
                                                            Support Forum
                                                        </a>
                                                    </div>
                                                </div>
                                                {/*end::Demo*/}
                                            </div>
                                            {/*end::Col*/}
                                        </div>
                                        {/*end::Row*/}
                                    </div>
                                    {/*end::Demos*/}
                                </div>
                                {/*end::Wrapper*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Card*/}
                </div>
                {/*end::Demos drawer*/}
                {/*begin::Help drawer*/}
                <div
                    id="kt_help"
                    className="bg-body"
                    data-kt-drawer="true"
                    data-kt-drawer-name="help"
                    data-kt-drawer-activate="true"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-width="{default:'350px', 'md': '525px'}"
                    data-kt-drawer-direction="end"
                    data-kt-drawer-toggle="#kt_help_toggle"
                    data-kt-drawer-close="#kt_help_close"
                >
                    {/*begin::Card*/}
                    <div className="card shadow-none rounded-0 w-100">
                        {/*begin::Header*/}
                        <div className="card-header" id="kt_help_header">
                            <h5 className="card-title fw-semibold text-gray-600">
                                Learn &amp; Get Inspired
                            </h5>
                            <div className="card-toolbar">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-icon explore-btn-dismiss me-n5"
                                    id="kt_help_close"
                                >
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                    <span className="svg-icon svg-icon-2">
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                opacity="0.5"
                                                x={6}
                                                y="17.3137"
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            />
                                            <rect
                                                x="7.41422"
                                                y={6}
                                                width={16}
                                                height={2}
                                                rx={1}
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </button>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body" id="kt_help_body">
                            {/*begin::Content*/}
                            <div
                                id="kt_help_scroll"
                                className="hover-scroll-overlay-y"
                                data-kt-scroll="true"
                                data-kt-scroll-height="auto"
                                data-kt-scroll-wrappers="#kt_help_body"
                                data-kt-scroll-dependencies="#kt_help_header"
                                data-kt-scroll-offset="5px"
                            >
                                {/*begin::Support*/}
                                <div className="rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10">
                                    {/*begin::Heading*/}
                                    <h2 className="fw-bold mb-5">
                                        Support at
                                        <a
                                            href="https://devs.keenthemes.com"
                                            className=""
                                        >
                                            devs.keenthemes.com
                                        </a>
                                    </h2>
                                    {/*end::Heading*/}
                                    {/*begin::Description*/}
                                    <div className="fs-5 fw-semibold mb-5">
                                        <span className="text-gray-500">
                                            Join our developers community to
                                            find answer to your question and
                                            help others.
                                        </span>
                                        <a
                                            className="explore-link d-none"
                                            href="/"
                                        >
                                            FAQs
                                        </a>
                                    </div>
                                    {/*end::Description*/}
                                    {/*begin::Link*/}
                                    <a
                                        href="https://devs.keenthemes.com"
                                        className="btn btn-lg explore-btn-primary w-100"
                                    >
                                        Get Support
                                    </a>
                                    {/*end::Link*/}
                                </div>
                                {/*end::Support*/}
                                {/*begin::Link*/}
                                <div className="d-flex align-items-center mb-7">
                                    {/*begin::Icon*/}
                                    <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning">
                                        {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                        <span className="svg-icon svg-icon-warning svg-icon-2x svg-icon-lg-3x">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-column me-2 me-lg-5">
                                            {/*begin::Title*/}
                                            <a
                                                href="/"
                                                className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                                            >
                                                Documentation &amp; Videos
                                            </a>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fw-semibold fs-7 fs-lg-6">
                                                From guides and video tutorials,
                                                to live demos and code examples
                                                to get started.
                                            </div>
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                        <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Link*/}
                                {/*begin::Link*/}
                                <div className="d-flex align-items-center mb-7">
                                    {/*begin::Icon*/}
                                    <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary">
                                        {/*begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg*/}
                                        <span className="svg-icon svg-icon-primary svg-icon-2x svg-icon-lg-3x">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M21 9V11C21 11.6 20.6 12 20 12H14V8H20C20.6 8 21 8.4 21 9ZM10 8H4C3.4 8 3 8.4 3 9V11C3 11.6 3.4 12 4 12H10V8Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15 2C13.3 2 12 3.3 12 5V8H15C16.7 8 18 6.7 18 5C18 3.3 16.7 2 15 2Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M9 2C10.7 2 12 3.3 12 5V8H9C7.3 8 6 6.7 6 5C6 3.3 7.3 2 9 2ZM4 12V21C4 21.6 4.4 22 5 22H10V12H4ZM20 12V21C20 21.6 19.6 22 19 22H14V12H20Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-column me-2 me-lg-5">
                                            {/*begin::Title*/}
                                            <a
                                                href="/"
                                                className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                                            >
                                                Plugins &amp; Components
                                            </a>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fw-semibold fs-7 fs-lg-6">
                                                Check out our 300+ in-house
                                                components and customized
                                                3rd-party plugins.
                                            </div>
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                        <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Link*/}
                                {/*begin::Link*/}
                                <div className="d-flex align-items-center mb-7">
                                    {/*begin::Icon*/}
                                    <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-info">
                                        {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                                        <span className="svg-icon svg-icon-info svg-icon-2x svg-icon-lg-3x">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-column me-2 me-lg-5">
                                            {/*begin::Title*/}
                                            <a
                                                href="/"
                                                className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                                            >
                                                Layout Builder
                                            </a>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fw-semibold fs-7 fs-lg-6">
                                                Build your layout, preview it
                                                and export the HTML for server
                                                side integration.
                                            </div>
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                        <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Link*/}
                                {/*begin::Link*/}
                                <div className="d-flex align-items-center mb-7">
                                    {/*begin::Icon*/}
                                    <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-success">
                                        {/*begin::Svg Icon | path: icons/duotune/files/fil021.svg*/}
                                        <span className="svg-icon svg-icon-success svg-icon-2x svg-icon-lg-3x">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-column me-2 me-lg-5">
                                            {/*begin::Title*/}
                                            <a
                                                href="https://devs.keenthemes.com/metronic"
                                                className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                                            >
                                                Metronic Downloads
                                            </a>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fw-semibold fs-7 fs-lg-6">
                                                Download your prefered framework
                                                and demo with one click.
                                            </div>
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                        <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Link*/}
                                {/*begin::Link*/}
                                <div className="d-flex align-items-center mb-7">
                                    {/*begin::Icon*/}
                                    <div className="d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-danger">
                                        {/*begin::Svg Icon | path: icons/duotune/electronics/elc009.svg*/}
                                        <span className="svg-icon svg-icon-danger svg-icon-2x svg-icon-lg-3x">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M13 9V8C13 7.4 13.4 7 14 7H15C16.7 7 18 5.7 18 4V3C18 2.4 17.6 2 17 2C16.4 2 16 2.4 16 3V4C16 4.6 15.6 5 15 5H14C12.3 5 11 6.3 11 8V9H13Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    opacity="0.3"
                                                    d="M21 22H3C2.4 22 2 21.6 2 21V10C2 9.4 2.4 9 3 9H21C21.6 9 22 9.4 22 10V21C22 21.6 21.6 22 21 22ZM5 12C4.4 12 4 12.4 4 13C4 13.6 4.4 14 5 14C5.6 14 6 13.6 6 13C6 12.4 5.6 12 5 12ZM8 12C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14C8.6 14 9 13.6 9 13C9 12.4 8.6 12 8 12ZM11 12C10.4 12 10 12.4 10 13C10 13.6 10.4 14 11 14C11.6 14 12 13.6 12 13C12 12.4 11.6 12 11 12ZM14 12C13.4 12 13 12.4 13 13C13 13.6 13.4 14 14 14C14.6 14 15 13.6 15 13C15 12.4 14.6 12 14 12ZM9 15C8.4 15 8 15.4 8 16C8 16.6 8.4 17 9 17C9.6 17 10 16.6 10 16C10 15.4 9.6 15 9 15ZM12 15C11.4 15 11 15.4 11 16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16C13 15.4 12.6 15 12 15ZM15 15C14.4 15 14 15.4 14 16C14 16.6 14.4 17 15 17C15.6 17 16 16.6 16 16C16 15.4 15.6 15 15 15ZM19 18C18.4 18 18 18.4 18 19C18 19.6 18.4 20 19 20C19.6 20 20 19.6 20 19C20 18.4 19.6 18 19 18ZM7 19C7 18.4 6.6 18 6 18H5C4.4 18 4 18.4 4 19C4 19.6 4.4 20 5 20H6C6.6 20 7 19.6 7 19ZM7 16C7 15.4 6.6 15 6 15H5C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17H6C6.6 17 7 16.6 7 16ZM17 14H19C19.6 14 20 13.6 20 13C20 12.4 19.6 12 19 12H17C16.4 12 16 12.4 16 13C16 13.6 16.4 14 17 14ZM18 17H19C19.6 17 20 16.6 20 16C20 15.4 19.6 15 19 15H18C17.4 15 17 15.4 17 16C17 16.6 17.4 17 18 17ZM17 19C17 18.4 16.6 18 16 18H9C8.4 18 8 18.4 8 19C8 19.6 8.4 20 9 20H16C16.6 20 17 19.6 17 19Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-stack flex-grow-1 ms-4 ms-lg-6">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-column me-2 me-lg-5">
                                            {/*begin::Title*/}
                                            <a
                                                href="/"
                                                className="text-dark text-hover-primary fw-bold fs-6 fs-lg-4 mb-1"
                                            >
                                                What's New ?
                                            </a>
                                            {/*end::Title*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fw-semibold fs-7 fs-lg-6">
                                                Latest features and improvements
                                                added with our users feedback in
                                                mind.
                                            </div>
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                                        <span className="svg-icon svg-icon-gray-400 svg-icon-2">
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x={18}
                                                    y={13}
                                                    width={13}
                                                    height={2}
                                                    rx={1}
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Link*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Card*/}
                </div>
                {/*end::Help drawer*/}
                {/*end::Engage drawers*/}
                {/*begin::Engage modals*/}
                {/*end::Engage modals*/}
                {/*begin::Engage toolbar*/}
                <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2">
                    {/*begin::Demos drawer toggle*/}
                    <button
                        id="kt_engage_demos_toggle"
                        className="engage-demos-toggle engage-btn btn shadow-sm fs-6 px-4 rounded-top-0"
                        title="Check out 30 more demos"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-dismiss="click"
                        data-bs-trigger="hover"
                    >
                        <span id="kt_engage_demos_label">Demos</span>
                    </button>
                    {/*end::Demos drawer toggle*/}
                    {/*begin::Help drawer toggle*/}
                    <button
                        id="kt_help_toggle"
                        className="engage-help-toggle btn engage-btn shadow-sm px-5 rounded-top-0"
                        title="Learn & Get Inspired"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-dismiss="click"
                        data-bs-trigger="hover"
                    >
                        Help
                    </button>
                    {/*end::Help drawer toggle*/}
                </div>
                {/*end::Engage toolbar*/}
                {/*begin::Scrolltop*/}
                <div
                    id="kt_scrolltop"
                    className="scrolltop"
                    data-kt-scrolltop="true"
                >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                    <span className="svg-icon">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                opacity="0.5"
                                x={13}
                                y={6}
                                width={13}
                                height={2}
                                rx={1}
                                transform="rotate(90 13 6)"
                                fill="currentColor"
                            />
                            <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}
                </div>

                {/* {AddLibrary('./assets/plugins/global/plugins.bundle.js')}
                {AddLibrary('./assets/js/scripts.bundle.js')}
                {AddLibrary(
                    './assets/plugins/custom/fslightbox/fslightbox.bundle.js',
                )}
                {AddLibrary(
                    './assets/plugins/custom/typedjs/typedjs.bundle.js',
                )}
                {AddLibrary('./assets/js/custom/landing.js')}
                {AddLibrary('./assets/js/custom/pages/pricing/general.js')} */}
            </div>
        </>
    );
}
